import { useRouteError } from "react-router-dom";

import { useLaunchParams } from "@telegram-apps/sdk-react";

import { ErrorComponent } from "./error";

type Props = {
  showNavbar?: boolean;
};

export const RouteError = ({ showNavbar }: Props) => {
  const error = useRouteError();
  const launchParams = useLaunchParams();

  return (
    <ErrorComponent
      error={error}
      showNavbar={showNavbar}
      user={launchParams.initData?.user}
    />
  );
};
