import { createContext, useContext } from "react";

import invariant from "tiny-invariant";

interface TMAContext {
  isTMA: boolean;
}

export const TMACtx = createContext<TMAContext | undefined>(undefined);

export function useIsTMA() {
  const ctx = useContext(TMACtx);
  invariant(ctx, "useIsTMA must be used within TMAContext.Provider");

  return ctx.isTMA;
}
