import { useState } from "react";

import { hapticFeedback } from "@telegram-apps/sdk-react";
import Decimal from "decimal.js";
import { DateTime, Duration } from "luxon";
import { twJoin } from "tailwind-merge";

import { ConnectWalletButton } from "features/connect-wallet";
import { Option } from "features/select-currency";
import { Border, Layout, LayoutTitle } from "pages/ui/layout";
import { Allocation } from "shared/graphql";
import { useCountdown } from "shared/hooks";
import { useSwitchChain, useWallet } from "shared/providers/web3-sdk";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { plural } from "shared/utils";
import {
  SaleFormBottomInfo,
  SaleFormInputData,
  SaleFormOutputData,
  SaleFormSeparator,
  SaleFormTitle,
} from "widgets/sale-form";

import { useBuyMutation } from "../hooks/use-buy-mutation";
import {
  getCurrencySelectorOptions,
  getPrice,
  getUsdRate,
} from "../utils/sale";

type Props = {
  initialBlockchain: string | undefined;
  initialSymbol: string | undefined;
  initialNodes: number | undefined;
  allocation: Allocation;
  isShard: boolean;

  onTryInBrowser: (reserveAmount: string, tokenId: string) => void;
};

const initPayValue = (
  allocation: Allocation,
  amount: string,
  nodesAmount: number
) => {
  return new Decimal(allocation.node_price_gnet.amount)
    .mul(nodesAmount)
    .mul(amount)
    .toString();
};

export const Sale = (props: Props) => {
  const {
    allocation,
    initialBlockchain,
    initialSymbol,
    initialNodes,
    isShard,
    onTryInBrowser,
  } = props;

  const [closedUntil] = useState(
    DateTime.fromJSDate(new Date(allocation.closed_until!))
      .diffNow()
      .rescale()
      .as("seconds")
  );

  const [count] = useCountdown(closedUntil, () => {});

  const { hours, minutes, seconds } = Duration.fromObject({
    hours: 0,
    minutes: 0,
    seconds: count,
  })
    .normalize()
    .toObject();

  const [blockchain, setBlockchain] = useState(
    initialBlockchain ?? allocation.price_gnet[0].meta.blockchain
  );
  const [symbol, setSymbol] = useState(
    initialSymbol ?? allocation.price_gnet[0].meta.symbol
  );

  const { isConnected } = useWallet(blockchain);
  const [shouldSwitch] = useSwitchChain(blockchain);

  const price = getPrice(allocation, blockchain, symbol);
  const { amount, meta: currency } = price;

  const [payValue, setPayValue] = useState<string | undefined>(
    initPayValue(allocation, amount, initialNodes ?? 1)
  );

  const [nodes, setNodes] = useState(initialNodes ?? 1);

  const options = getCurrencySelectorOptions(allocation);
  const usdRate = getUsdRate(allocation, currency.token_id);

  const buyMutation = useBuyMutation();

  // it isn't being used
  const handlePayValueChange = (value: string | undefined) => {
    if (!value) {
      setPayValue(value);
      setNodes(0);
      return;
    }

    const gnetAmountDecimal = new Decimal(value).div(amount);

    const nodes = gnetAmountDecimal
      .div(allocation.node_price_gnet.amount)
      .toNumber();

    setPayValue(value);
    setNodes(Math.min(allocation.max_nodes, Math.floor(nodes)));
  };

  const handleNodesChange = (nodes: number) => {
    hapticFeedback.impactOccurred("light");

    const gnetNeededDecimal = new Decimal(
      allocation.node_price_gnet.amount
    ).mul(nodes);
    const newPayValueDecimal = gnetNeededDecimal.mul(amount);

    setNodes(nodes);
    setPayValue(newPayValueDecimal.toString());
  };

  const handleSelectToken = (option: Option) => {
    hapticFeedback.impactOccurred("light");
    const { blockchain, symbol } = option;
    const { amount } = getPrice(allocation, blockchain, symbol);

    setPayValue(initPayValue(allocation, amount, nodes));
    setSymbol(symbol);
    setBlockchain(blockchain);
  };

  const handleBuy = () => {
    hapticFeedback.impactOccurred("medium");
    buyMutation.mutate({
      amount_gnet: new Decimal(allocation.node_price_gnet.amount)
        .mul(nodes)
        .toString(),
      payment_token_id: currency.token_id,
    });
  };

  const renderButtonText = () => {
    if (nodes === 0) return `MIN AMOUNT 1`;

    return `Buy FOR ${symbol}`;
  };

  const showTimer = count > 0;

  const label = isShard
    ? plural(nodes, ["SHARD", "SHARDS", "SHARDS"])
    : plural(nodes, ["NODE", "NODES", "NODES"]);

  const title = (
    <LayoutTitle className="gap-x-2.5">
      <Icon name="bluewhale" className="size-6" /> BLUWHALE NODE SALE
    </LayoutTitle>
  );
  return (
    <>
      <Layout contentClasName={twJoin("px-0 pb-0")} title={title}>
        <Border />
        <SaleFormTitle className="px-5">{allocation.tier_name}</SaleFormTitle>

        <SaleFormInputData
          className="mt-8 px-5"
          options={options}
          payValue={payValue}
          usdRate={usdRate ?? "0"}
          symbol={symbol}
          blockchain={blockchain}
          decimals={currency?.decimals ?? 6}
          onPayValueChange={handlePayValueChange}
          onCurrencySelect={handleSelectToken}
        />

        <SaleFormSeparator className="px-5" />

        <SaleFormOutputData
          className="px-5"
          min={1}
          max={allocation.max_nodes}
          onChange={handleNodesChange}
          value={nodes}
          label={label}
        />
        {isShard && (
          <span className="my-2 flex justify-center text-sm text-goldenrod/50">
            1 NODE = {new Decimal(1).div(allocation.fraction || 1).toString()}{" "}
            SHARDS
          </span>
        )}

        <div className="mt-auto flex flex-col px-5">
          {showTimer && (
            <Button
              theme="gold-opacity"
              className="mt-5 h-[48px] w-full uppercase"
              disabled={true}
            >
              open in {hours?.toString().padStart(2, "0")}:
              {minutes?.toString().padStart(2, "0")}:
              {seconds?.toFixed(0).toString().padStart(2, "0")}
            </Button>
          )}

          {!showTimer && (
            <>
              {isConnected && !shouldSwitch && (
                <Button
                  className="mb-3"
                  disabled={!nodes}
                  isLoading={buyMutation.isPending}
                  onClick={handleBuy}
                >
                  <Button.Content>{renderButtonText()}</Button.Content>
                </Button>
              )}

              <ConnectWalletButton
                connectContent={
                  blockchain === "TON" ? "Connect Wallet" : "Connect MetaMask"
                }
                disabled={buyMutation.isPending}
                className="w-full"
                blockchain={blockchain}
              />

              {!isConnected && blockchain !== "TON" && (
                <Link
                  className="mt-3"
                  as="button"
                  onClick={() =>
                    onTryInBrowser(
                      new Decimal(allocation.node_price_gnet.amount)
                        .mul(nodes)
                        .toString(),
                      currency.token_id
                    )
                  }
                >
                  Use another wallet / Web App
                </Link>
              )}
            </>
          )}
        </div>

        <SaleFormBottomInfo
          availableNodes={allocation.available_nodes}
          totalNodes={allocation.total_nodes}
          className="mt-5 w-[98%]"
          label={isShard ? "SHARDS" : "NODES"}
        />
      </Layout>
    </>
  );
};
