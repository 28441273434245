import { type Address, type Cell, beginCell } from "@ton/core";

function getRandomQueryId(): bigint {
  return BigInt(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
}

export function prepareJettonTransferMessage(
  amount: bigint,
  destinationAddress: Address,
  responseAddress: Address,
  forwardAmount: bigint,
  forwardPayload: string
): Cell {
  const queryId = getRandomQueryId();
  return beginCell()
    .storeUint(0x0f8a7ea5, 32)
    .storeUint(queryId, 64)
    .storeCoins(amount)
    .storeAddress(destinationAddress)
    .storeAddress(responseAddress)
    .storeBit(0)
    .storeCoins(forwardAmount)
    .storeBit(1)
    .storeRef(
      beginCell().storeUint(0, 32).storeStringTail(forwardPayload).endCell()
    )
    .endCell();
}
