import { useMutation } from "@tanstack/react-query";
import {
  Address,
  concat,
  encodeFunctionData,
  erc20Abi,
  parseEther,
  parseUnits,
  toHex,
  zeroAddress,
} from "viem";
import { useAccount, useChainId, useWalletClient } from "wagmi";

type Params = {
  reservationProof: string;
  contractAddress: Address;
  paymentAddress: Address;

  currencyAmount: string;
  currencyDecimal: number;
};

export const useBuyMutation = () => {
  const { data: wc } = useWalletClient();
  const { address } = useAccount();
  const chainId = useChainId();

  return useMutation({
    mutationFn: async ({
      reservationProof,
      contractAddress,
      paymentAddress,
      currencyAmount,
      currencyDecimal,
    }: Params) => {
      if (!wc) throw new Error("wc is undefined");
      const hexedProof = toHex(reservationProof);
      let data = hexedProof;

      if (contractAddress && contractAddress !== zeroAddress) {
        const transferData = encodeFunctionData({
          abi: erc20Abi,
          functionName: "transfer",
          args: [
            paymentAddress as Address,
            parseUnits(currencyAmount, currencyDecimal),
          ],
        });
        data = concat([transferData, hexedProof]);
      }

      const hash = await wc.sendTransaction({
        from: address,
        chainId: chainId,
        to: contractAddress === zeroAddress ? paymentAddress : contractAddress,
        value:
          contractAddress === zeroAddress
            ? parseEther(currencyAmount)
            : undefined,
        data: data as `0x${string}`,
        gas: 200000n,
      });
      return hash;
    },
  });
};
