import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { createClient, http } from "viem";
import { createConfig } from "wagmi";
import {
  arbitrum,
  base,
  bsc,
  Chain,
  optimism,
  polygon,
  sepolia,
  mainnet,
} from "wagmi/chains";

export const supportedChains: [Chain, ...Chain[]] = [
  import.meta.env.VITE_ENV === "prod" ? mainnet : sepolia,
  arbitrum,
  base,
  bsc,
  optimism,
  polygon,
];

const projectId = "06f9af383df73f3ca2bfc6c8ca4f664f";

export const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet, walletConnectWallet],
    },
  ],
  {
    appName: "CT.com app",
    projectId: projectId,
  }
);

export const config = createConfig({
  chains: supportedChains,
  connectors,
  ssr: false,
  client: ({ chain }) => {
    return createClient({
      batch: {
        multicall: {
          batchSize: 1024 * 3, // 3kb
        },
      },
      chain,
      transport: http(chain.rpcUrls.default.http[0]),
    });
  },
});
