import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { TonConnectUIProvider } from "@tonconnect/ui-react";

import { TMACtx } from "./context";
import { initializeTMA, mockEnv } from "./init";

export function Provider({ children }: PropsWithChildren) {
  const [isTMA, setIsTMA] = useState<boolean | undefined>();
  const promiseRef = useRef<Promise<void>>();

  const manifestUrl = useMemo(() => {
    return new URL(
      import.meta.env.VITE_ENVIRONMENT === 'prod' || import.meta.env.VITE_ENVIRONMENT === 'bluwhale-prod'
        ? 'tonconnect-manifest.json'
        : 'tonconnect-manifest-dev.json',
      window.location.href,
    ).toString()
  }, [])

  const tryInitTMA = useCallback(async () => {
    try {
      if (!promiseRef.current) {
        promiseRef.current = initializeTMA();
      }

      await promiseRef.current;

      setIsTMA(true);
    } catch {
      mockEnv();
      setIsTMA(false);
    }
  }, []);

  useEffect(() => {
    tryInitTMA();
  }, [tryInitTMA]);

  if (isTMA === undefined)
    // TODO: loading screen or suspense
    return null;

  return (
    <TMACtx.Provider value={{ isTMA }}>
      <TonConnectUIProvider manifestUrl={manifestUrl}>
        {children}
      </TonConnectUIProvider>
    </TMACtx.Provider>
  );
}
