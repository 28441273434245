/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback } from "react";

import { useSDK } from "@metamask/sdk-react";
import { hapticFeedback } from "@telegram-apps/sdk-react";
import { fromHex, isHex, toHex } from "viem";
import { useChains } from "wagmi";

import { Blockchain } from "shared/graphql";
import { chainMap } from "shared/web3/consts";

import { useWallet } from "./use-wallet";

export const useSwitchChain = (
  blockchain: Omit<string, Blockchain> | Blockchain
) => {
  const chains = useChains();
  const { sdk } = useSDK();
  const { chainId } = useWallet(blockchain);

  const targetChainId = chainMap[blockchain as string];

  const normalizedChainId = isHex(chainId)
    ? fromHex(chainId, "number")
    : chainId;

  const shouldSwitch = normalizedChainId !== targetChainId;

  const switchChain = useCallback(async () => {
    hapticFeedback.impactOccurred("medium");
    const chainId = targetChainId;
    try {
      await sdk?.activeProvider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(chainId) }],
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (
        // @ts-ignore
        err.code === 4902 ||
        // @ts-ignore
        err.message.includes("Unrecognized chain ID")
      ) {
        const chain = chains.find((chain) => chain.id === chainId);
        if (!chain) return;
        await sdk?.activeProvider?.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: chain.name,
              chainId: toHex(chain.id),
              nativeCurrency: chain.nativeCurrency,
              rpcUrls: [chain.rpcUrls.default.http[0]],
            },
          ],
        });
      }
    }
  }, [chains, targetChainId, sdk]);

  return [shouldSwitch, switchChain] as const;
};
