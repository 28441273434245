import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { hapticFeedback, openLink } from "@telegram-apps/sdk-react";
import copy from "copy-to-clipboard";

import { generateWebLink } from "pages/sale/utils/generate-web-link";
import { queries, useReserveMutation } from "shared/api";
import { Allocation, Reservation } from "shared/graphql";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { Modal } from "shared/ui/modal";
import { Spinner } from "shared/ui/spinner";

import iconSvg from "../icon.svg";

type Props = {
  reserveAmount: string;
  tokenId: string;
  allocation: Allocation;
  reservation: Reservation | undefined;
  onClose: () => void;
};

export const PayInBrowserModal = (props: Props) => {
  const queryClient = useQueryClient();
  const { onClose, allocation, reservation, reserveAmount, tokenId } = props;

  const reserveMutation = useReserveMutation();
  const [copied, setCopied] = useState(false);

  const buildLink = async () => {
    let reserve = reservation;

    if (!reserve) {
      const response = await reserveMutation.mutateAsync({
        amount_gnet: reserveAmount,
        payment_token_id: tokenId,
      });

      await queryClient.invalidateQueries({
        queryKey: queries.unpaidReserves(),
      });

      reserve = response.data.reserve;
    }
    hapticFeedback.impactOccurred("light");
    return generateWebLink(allocation, reserve);
  };

  const handleClick = async () => {
    try {
      const link = await buildLink();
      setTimeout(() => {
        openLink(link);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopy = async () => {
    try {
      const link = await buildLink();
      setTimeout(() => {
        const copied = copy(link);
        setCopied(copied);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal onClose={onClose}>
      {({ onChange }) => (
        <Modal.Overlay>
          <Modal.Content>
            <Modal.Body
              onClose={() => onChange(false)}
              className="w-[322px] items-center pb-[51px] pt-12"
            >
              <img src={iconSvg} alt="" className="" />
              <h3 className="mt-4.5 text-center text-xl text-white">
                Use your payment link!
              </h3>
              <p className="mt-3.5 text-center text-sm text-white/50">
                You can open it in any browser and use Wallet Connect to pay.
              </p>

              {reserveMutation.isPending && (
                <Spinner className="mx-auto my-2 size-7" />
              )}

              <button
                className="mt-5 flex w-full items-center justify-between rounded-lg border border-sandstorm/20 bg-sandstorm/10 py-3 pl-5 pr-4 text-[15px] uppercase transition-colors hover:bg-sandstorm/25"
                onClick={handleClick}
              >
                Open in Browser{" "}
                <Icon name="link" className="size-4 text-sandstorm/75" />
              </button>

              <Link
                className="mt-4 flex items-center gap-x-2 text-sm text-sandstorm"
                as="button"
                onClick={handleCopy}
              >
                Copy Link
                <Icon
                  name={copied ? "check" : "copy"}
                  className="size-4 opacity-50"
                />
              </Link>
            </Modal.Body>
          </Modal.Content>
        </Modal.Overlay>
      )}
    </Modal>
  );
};
