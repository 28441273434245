import { ComponentProps, PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

export const NodeButton = ({
  children,
  className,
  disabled,
  ...props
}: PropsWithChildren<ComponentProps<"button">>) => {
  return (
    <button
      className={twMerge(
        "flex size-[71px] items-center justify-center rounded-[10px] border border-white/20 outline-none transition-colors active:border-goldenrod active:brightness-110",
        disabled && "pointer-events-none",
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
