import { createContext, useContext } from "react";

import invariant from "tiny-invariant";

import type { AuthData } from "./types";

export const AuthContext = createContext<AuthData | undefined>(undefined);

export function useAuth() {
  const ctx = useContext(AuthContext);
  invariant(ctx, "useAuth must be used within AuthContext.Provider");
  return ctx;
}
