import { useQueryClient } from "@tanstack/react-query";
import { shareURL } from "@telegram-apps/sdk-react";
import { Duration } from "luxon";

import { Border, Layout } from "pages/ui/layout";
import { queries } from "shared/api";
import { useCountdown } from "shared/hooks";
import { useAuth } from "shared/providers/auth";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";

import crossSvg from "../assets/cross.svg";
import textSvg from "../assets/text.svg";
type Props = {
  value: number;
};

export const Cooldown = ({ value }: Props) => {
  const { session, user } = useAuth();
  const queryClient = useQueryClient();

  const [count] = useCountdown(value, () => {
    queryClient.setQueryData(
      queries.allocation({ session }).queryKey,
      (data) => {
        if (!data) return data;
        return { ...data, cooldownReserve: null };
      }
    );
  });

  const { hours, minutes, seconds } = Duration.fromObject({
    hours: 0,
    minutes: 0,
    seconds: count,
  })
    .normalize()
    .toObject();

  return (
    <Layout>
      <Border />
      <div className="flex h-full flex-col justify-between py-[16px]">
        <div className="flex items-center justify-center gap-x-14">
          <Icon name="bluewhale" className="size-[72px] text-white" />
          <img src={crossSvg} className="size-11" />
          <Icon name="logo" className="h-[72px] w-[52px]" />
        </div>
        <div className="flex flex-col">
          <h1 className="text-center text-[28px] font-semibold text-goldenrod">
            Bluwhale Nodesale
          </h1>
          <img className="h-3" src={textSvg} alt="" />

          <div className="flex items-end justify-center text-[60px] font-medium text-white">
            <span>{hours?.toString().padStart(2, "0")}</span>
            <span className="text-white/50">:</span>
            <span>{minutes?.toString().padStart(2, "0")}</span>
            <span className="text-white/50">:</span>
            <span>{seconds?.toString().padStart(2, "0")}</span>
          </div>
        </div>
        <Button
          theme="sandstorm-outline"
          className="w-full py-3 text-lg font-semibold uppercase text-goldenrod"
          onClick={() => {
            shareURL(
              " ",
              `I’ve just bought a Bluwhale Node! Join me in shaping the future of decentralized AI! Follow the link: https://t.me/BluwhaleNodesaleBot/app?startapp=ref_${user.ref_code}`
            );
          }}
        >
          Share Link
        </Button>
      </div>
    </Layout>
  );
};
