import { type PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { useLaunchParams } from "@telegram-apps/sdk-react";
import { isHex } from "viem";

import { EnterRefCode } from "features/enter-ref-code";
import { Preloader } from "pages/sale";
import { queries, useAttachRefCodeMutation } from "shared/api";
import { User } from "shared/graphql";
import { AuthContext, useAuthStore } from "shared/providers/auth";
import { useIsTMA } from "shared/providers/tma";

export function AuthGuard({ children }: PropsWithChildren) {
  const isTMA = useIsTMA();
  const [authData] = useAuthStore();
  const launchParams = useLaunchParams();
  const attachRefMutation = useAttachRefCodeMutation();

  const handleUserFetch = async (user: User, session: string) => {
    const launchRef = launchParams.startParam?.replace("ref_", "");

    if (launchRef && !isHex(launchRef) && user.ref_code !== launchRef) {
      try {
        const response = await attachRefMutation.mutateAsync({
          session: session,
          refCode: launchRef,
        });

        return response.attachRefCode;
      } catch {
        return user;
      }
    }

    return user;
  };

  const authQuery = useQuery(
    queries.auth(
      { session: authData?.session },
      { onUserFetch: handleUserFetch }
    )
  );

  if (!isTMA && !import.meta.env.DEV)
    throw new Error("TMA doesn't work in browser");

  if (authQuery.isLoading) return <Preloader />;

  const isRefCodeInvalid =
    attachRefMutation.error?.message.includes("Invalid ref code");

  if (authQuery.isSuccess || isRefCodeInvalid) {
    return (
      <AuthContext.Provider value={authQuery.data}>
        {isRefCodeInvalid && (
          <EnterRefCode
            tierName=""
            title="Your link is invalid."
            description="Please, try using another one or enter referral code."
            onSubmit={attachRefMutation.reset}
          />
        )}
        {!isRefCodeInvalid && (children ?? <Outlet />)}
      </AuthContext.Provider>
    );
  }

  if (authQuery.error) throw authQuery.error;
  if (attachRefMutation.error) throw attachRefMutation.error;

  return null;
}
