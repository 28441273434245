import { twMerge } from "tailwind-merge";

import { Checkbox } from "shared/ui/checkbox";

type Props = {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
};

export const PolicyCheckbox = (props: Props) => {
  const { className, checked, onChange } = props;

  return (
    <label
      className={twMerge(
        "flex items-center gap-x-1.5 text-xs font-light",
        className
      )}
    >
      <Checkbox checked={checked} onChange={onChange} />
      <span>
        I agree to the{" "}
        <a href="#" className="underline">
          Terms of use
        </a>{" "}
        of the app
      </span>
    </label>
  );
};
