import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "../providers/auth";
import { sdk } from "../providers/graphql-client";
import { queries } from "./query-factory";

export function useCancelReserveMutation() {
  const auth = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const res = await sdk.CancelReserve({ session: auth.session });
      return res.data.cancelReserve;
    },

    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queries.unpaidReserves() }),
        queryClient.invalidateQueries({ queryKey: queries.allocations() }),
      ]);
    },
  });
}
