import { twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";

import { NodeButton } from "./node-button";

type Props = {
  disabled?: boolean;
  className?: string;
  max: number;
  min: number;
  value: number;
  label: string;
  onChange: (nodes: number) => void;
};

export const OutputData = (props: Props) => {
  const { onChange, disabled, max, className, value, label, min } = props;

  const handleChange = (value: number) => {
    onChange(Math.min(Math.max(value, min), max));
  };

  const minusDisabled = disabled || value === min;
  const plusDisabled = disabled || value === max;

  return (
    <div className={twMerge("flex flex-col", className)}>
      <div className="flex items-center justify-between">
        <span className="text-xs font-semibold text-white/50">You receive</span>
        <button
          disabled={disabled}
          onClick={() => {
            handleChange(max);
          }}
          className={twMerge(
            "flex rounded-full border border-white/20 px-3.5 py-1 leading-none text-goldenrod outline-none transition-colors hover:brightness-105 active:border-goldenrod active:brightness-110",
            disabled && "pointer-events-none"
          )}
        >
          MAX
        </button>
      </div>

      <div className="mt-4.5 flex justify-between text-goldenrod">
        <NodeButton
          disabled={minusDisabled}
          onClick={() => {
            handleChange(value - 1);
          }}
        >
          <Icon
            name="minus"
            className={twMerge("size-9", minusDisabled && "text-white/50")}
          />
        </NodeButton>
        <div className="flex flex-col items-center justify-center font-semibold">
          <span className="max-w-44 truncate text-[64px] leading-[50px]">
            {value}
          </span>
          <span className="font-semibold">{label}</span>
        </div>
        <NodeButton
          disabled={plusDisabled}
          onClick={() => {
            handleChange(value + 1);
          }}
        >
          <Icon
            name="plus"
            className={twMerge("size-9", plusDisabled && "text-white/50")}
          />
        </NodeButton>
      </div>
    </div>
  );
};
