import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";
import { getExplorerLink } from "shared/web3/utils";

import { type HistoryStatus, Status } from "./status";

type Props = {
  txHash: string;
  paymentTokenSymbol: string;
  paymentAmount: string;
  blockchain: string;
  nodes: number;
  label: string;
  className?: string;
  status: HistoryStatus;
};

export const HistoryRow = (props: Props) => {
  const {
    txHash,
    status,
    className,
    blockchain,
    nodes,
    label,
    paymentTokenSymbol,
    paymentAmount,
  } = props;

  // const formattedPaymentInfo = `${formatCurrency(paymentAmount, { decimalSeparator: "." })} ${paymentTokenSymbol}`;
  const formattedPaymentInfo = `${paymentAmount} ${paymentTokenSymbol}`;

  return (
    <div className={twMerge("relative flex flex-col", className)}>
      <a
        href={getExplorerLink(txHash, blockchain)}
        target="_blank"
        className="absolute right-0 top-0 size-4 text-white/30 transition-colors hover:text-goldenrod active:text-goldenrod"
      >
        <Icon name="link" className="size-full" />
      </a>
      <Status
        className={twJoin(status === "error" && "text-white/50")}
        status={status}
        blockchain={blockchain}
      />
      <div className="mt-2.5 flex text-xs text-white/50">
        Recieved:{" "}
        <span
          className={twJoin(
            "ml-1 text-white",
            status === "error" && "text-white/50 line-through"
          )}
        >
          {nodes} {label}
        </span>
      </div>
      <div className="mt-1 flex text-xs text-white/50">
        Paid:{" "}
        <span
          className={twJoin(
            "ml-1 text-white/50",
            status === "error" && "line-through"
          )}
        >
          {formattedPaymentInfo}
        </span>
      </div>
    </div>
  );
};
