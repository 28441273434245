import { twMerge } from "tailwind-merge";

import nodeSvg from "shared/ui/icon/svg/node.svg";
import { Img } from "shared/ui/img";

type Props = {
  className?: string;
  count: number;
  label: string;
  img?: string;
};

export const NodesRows = (props: Props) => {
  const { count, className, label, img } = props;
  return (
    <div
      className={twMerge(
        "flex w-full flex-col justify-center gap-x-5 font-semibold",
        className
      )}
    >
      <div className="flex items-center justify-center gap-x-3 text-[42px] text-goldenrod">
        <span className="leading-none">{count}</span>
        <span className="text-base leading-none">
          BLUWHALE <br /> {label}
        </span>
      </div>

      <div className="mt-2 flex flex-wrap justify-center gap-3">
        {Array.from({ length: count }).map((_, idx) => {
          return (
            <Img key={idx} className="size-10" src={img ?? nodeSvg} alt="" />
          );
        })}
      </div>
    </div>
  );
};
