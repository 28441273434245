import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";
import { Img } from "shared/ui/img";

import { Option as OptionType } from "../types";

type Props = {
  className?: string;
  isSelected?: boolean;
  option: OptionType;

  onClick: () => void;
};

export const Option = ({ className, option, isSelected, onClick }: Props) => {
  const { blockchain, icon, symbol } = option;

  return (
    <li
      onClick={onClick}
      className={twMerge(
        "relative flex rounded-md px-5 py-1.5 odd:bg-white/3 active:brightness-125",
        className
      )}
    >
      <div className="flex items-center gap-x-2 text-xl text-white">
        <Img
          src={icon}
          className="inline-flex size-5 rounded-full"
          alt={symbol}
        />
        <span className={twJoin("inline-flex", isSelected && "text-goldenrod")}>
          {symbol}
        </span>
        {isSelected && <Icon name="check" className="size-3 text-goldenrod" />}
        <span className="rounded border border-white/10 px-3 py-[5px] font-minecraft text-sm leading-3 text-white/50">
          {blockchain}
        </span>
      </div>

      <Icon
        name="arrowRight"
        className="absolute right-5 top-1/2 size-3 -translate-y-1/2 text-white/50"
      />
    </li>
  );
};
