import { ErrorBoundary } from "react-error-boundary";
import { SkeletonTheme } from "react-loading-skeleton";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import {
  init,
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/react";

import { AccountPage } from "pages/account";
import { Dev } from "pages/dev";
import { ErrorComponent } from "pages/error";
import { RouteError } from "pages/error/route-error";
import { PortfolioPage } from "pages/portfolio";
import { SalePage } from "pages/sale";
import { WebPage } from "pages/web";
import { WelcomeScreen } from "pages/welcome";
import { TMAProvider } from "shared//providers/tma";
import { MetaMaskSDKProvider } from "shared/providers/metamask-sdk";
import { RqProvider } from "shared/providers/rq";
import { WagmiProvider } from "shared/providers/wagmi";

import { AuthGuard } from "./guards/auth";
import { NoMatch } from "./guards/no-match";

import "react-loading-skeleton/dist/skeleton.css";
import "@rainbow-me/rainbowkit/styles.css";

import "./index.css";

if (import.meta.env.VITE_ENV === "prod" && import.meta.env.PROD) {
  init({
    dsn: "https://7c8b6ee04e7a49d0a12c360c1f1751d6@o4505432424775680.ingest.us.sentry.io/4508177845846016",
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Tracing
    // tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RouteError />}>
      <Route element={<WelcomeScreen />}>
        <Route element={<AuthGuard />}>
          <Route element={<MetaMaskSDKProvider />}>
            <Route path="/" element={<SalePage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
          </Route>
        </Route>
      </Route>

      <Route
        path="/web"
        errorElement={<RouteError showNavbar={false} />}
        element={<WebPage />}
      />
      {import.meta.env.DEV && <Route path="/dev" element={<Dev />} />}

      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);

export function App() {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <ErrorComponent showNavbar={false} error={error} />
      )}
    >
      <TMAProvider>
        <WagmiProvider>
          <RqProvider>
            <SkeletonTheme baseColor="#FFFFFF24" highlightColor="#FFFFFF0B">
              <RouterProvider router={router} />
            </SkeletonTheme>
          </RqProvider>
        </WagmiProvider>
      </TMAProvider>
    </ErrorBoundary>
  );
}
