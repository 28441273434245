import Skeleton from "react-loading-skeleton";

import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

export const PreloaderRow = ({ className }: Props) => {
  return (
    <div className={twMerge("mt-7.5 flex flex-col", className)}>
      <div className="flex">
        <Skeleton className="mr-1.5 size-5 rounded-full" />
        <Skeleton className="mr-1.5 h-5 w-[154px] rounded" />
        <Skeleton className="mr-1.5 h-5 w-[34px] rounded-full" />
        <div className="ml-auto">
          <Skeleton className="size-5 rounded-full" />
        </div>
      </div>
      <div className="mt-2.5 flex">
        <Skeleton className="mr-1.5 h-4 w-[52px] rounded" />
        <Skeleton className="mr-1.5 h-4 w-[177px] rounded" />
      </div>
      <div className="mt-1.5 flex">
        <Skeleton className="mr-1.5 h-4 w-[34px] rounded" />
        <Skeleton className="mr-1.5 h-4 w-[70px] rounded" />
      </div>
    </div>
  );
};
