import Decimal from "decimal.js";
import { twJoin, twMerge } from "tailwind-merge";

import { Option, SelectCurrency } from "features/select-currency";
import { CurrencyInput } from "shared/ui/currency-input";
import { formatCurrency } from "shared/utils";

type Props = {
  disabled?: boolean;
  className?: string;
  symbol: string;
  blockchain: string;
  decimals: number;
  payValue: string | undefined;
  onPayValueChange: (value: string | undefined) => void;
  onCurrencySelect: (option: Option) => void;
  usdRate: string | undefined;
  options: Option[];
};

export const InputData = (props: Props) => {
  const {
    disabled,
    symbol,
    options,
    className,
    blockchain,
    usdRate,
    payValue,
    onPayValueChange,
    onCurrencySelect,
    decimals,
  } = props;

  const usdDecimal =
    payValue && usdRate
      ? new Decimal(usdRate).mul(new Decimal(payValue))
      : undefined;
  const usd = usdDecimal?.toFixed(1);
  const formattedUsd = usd
    ? formatCurrency(usd, {
        prefix: "$",
        groupSeparator: "",
        decimalSeparator: ".",
      })
    : undefined;

  return (
    <div className={twMerge("flex flex-col gap-y-1.5", className)}>
      <div className="text-xs text-white/50">You pay</div>
      <div className="flex items-center justify-between">
        <CurrencyInput
          disabled
          className="grow"
          decimals={decimals}
          value={payValue}
          onChange={onPayValueChange}
        />
        <SelectCurrency
          disabled={disabled}
          className="ml-2"
          symbol={symbol}
          blockchain={blockchain}
          options={options}
          onChange={onCurrencySelect}
        />
      </div>
      <div
        className={twJoin(
          "text-xs font-semibold text-white/50",
          !formattedUsd && "opacity-0"
        )}
      >
        ~ {formattedUsd}
      </div>
    </div>
  );
};
