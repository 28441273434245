import type { ElementType, PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

import type { PolymorphicProps } from "shared/types";

import { Spinner } from "../spinner";
import { Content } from "./button-content";

export type Theme = "goldGradient" | "sandstorm-outline" | "gold-opacity";

export interface Props {
  disabled?: boolean;
  isLoading?: boolean;
  theme?: Theme;
  className?: string;
}

export function Button<E extends ElementType = "button">(
  props: PropsWithChildren<PolymorphicProps<E, Props>>
) {
  const {
    as: Comp = "button",
    children,
    className,
    disabled = false,
    isLoading = false,
    theme = "goldGradient",
    ...restProps
  } = props;

  const content = isLoading ? (
    <span className="opacity-0">{children}</span>
  ) : (
    children
  );

  return (
    <Comp
      {...restProps}
      className={twMerge(
        "relative inline-flex cursor-pointer select-none items-center justify-center overflow-hidden rounded-lg p-1 text-center font-semibold outline-none transition",
        "hover:brightness-110 focus:brightness-90 active:brightness-90",
        (isLoading || disabled) && "pointer-events-none",

        // goldGradient
        theme === "goldGradient" && "bg-goldGradient text-birch shadow-button",
        theme === "goldGradient" && disabled && "opacity-50",
        theme === "gold-opacity" && "bg-goldenrod/10 text-goldenrod",
        // sandstorm-outline
        theme === "sandstorm-outline" &&
          "bg-transparent inner-border inner-border-sandstorm",
        theme === "sandstorm-outline" && disabled && "opacity-50",

        className
      )}
    >
      {isLoading && (
        <span className="absolute inset-0 flex items-center justify-center">
          <Spinner
            className={twMerge(
              "size-4",
              theme === "goldGradient" && "text-white"
            )}
          />
        </span>
      )}
      {content}
    </Comp>
  );
}

Button.Content = Content;
