import type { ComponentProps, ReactNode } from "react";

import { twMerge } from "tailwind-merge";

import type { ButtonProps } from "shared/ui/button";

import {
  useConnect,
  useDisconnect,
  useSwitchChain,
  useWallet,
} from "shared/providers/web3-sdk";
import { Button } from "shared/ui/button";
import { Link } from "shared/ui/link";

type Props = {
  blockchain: string;
  connectContent?: ReactNode;
  installMetamaskContent?: ReactNode;
  installSnapContent?: ReactNode;
  switchChainContent?: ReactNode;
  className?: string;
} & ButtonProps;

export function ConnectButton({
  className,
  blockchain,
  connectContent = "Connect Wallet",
  switchChainContent = "Switch Chain",
  isLoading,
  ...props
}: Props) {
  const { isDisconnected, isConnecting } = useWallet(blockchain);
  const [shouldSwitch, switchChain] = useSwitchChain(blockchain);

  const disconnect = useDisconnect(blockchain);
  const connect = useConnect(blockchain);

  const handleConnect = async () => {
    try {
      await connect();
    } catch (error) {
      console.error(error);
    }
  };

  const btnProps: ComponentProps<typeof Button> = {
    className: twMerge("gap-x-1.5 whitespace-nowrap", className),
    ...props,
  };

  if (isDisconnected || isConnecting) {
    return (
      <Button {...btnProps} isLoading={isConnecting} onClick={handleConnect}>
        <Button.Content>{connectContent}</Button.Content>
      </Button>
    );
  }

  if (shouldSwitch) {
    return (
      <>
        <Button
          {...btnProps}
          onClick={() => {
            switchChain();
          }}
        >
          <Button.Content>{switchChainContent}</Button.Content>
        </Button>
        <Link as="button" className="mt-3" onClick={disconnect}>
          Disconnect wallet
        </Link>
      </>
    );
  }

  return (
    <Link
      {...btnProps}
      as="button"
      className={btnProps.className}
      onClick={disconnect}
    >
      Disconnect wallet
    </Link>
  );
}
