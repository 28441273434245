import { FormEventHandler, useState } from "react";

import { ClientError } from "graphql-request";
import { twJoin } from "tailwind-merge";

import { Border, Layout } from "pages/ui/layout";
import { useAttachRefCodeMutation } from "shared/api";
import { useAuth } from "shared/providers/auth";
import { FormInput } from "shared/ui/input";

type Props = {
  tierName: string;
  title: string;
  description: string;
  onSubmit?: () => void;
};

export const EnterRefCode = ({
  tierName,
  title,
  description,
  onSubmit,
}: Props) => {
  const { user, session } = useAuth();
  const [error, setError] = useState<undefined | string>();
  const [manualRefCode, setManualRefCode] = useState(user.ref_code || "");
  const attachRefCodeMutation = useAttachRefCodeMutation();

  const handleRefCodeSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setError(undefined);

    if (!manualRefCode) {
      setError("Please enter a referral code");
      return;
    }

    try {
      await attachRefCodeMutation.mutateAsync({
        session,
        refCode: manualRefCode,
      });
      onSubmit?.();
    } catch (err) {
      const errorMessage =
        err instanceof ClientError
          ? err.response.errors?.[0].message
          : "An unknown error occurred";
      setError(errorMessage);
    }
  };

  return (
    <Layout contentClasName={twJoin("justify-between")}>
      <Border type="withBottomShadow" />
      {tierName ? <h2>{tierName}</h2> : <div />}
      <div className="relative mx-9 flex flex-col justify-center text-center">
        <h1 className="text-2xl text-white">{title}</h1>
        <p className="mt-4 text-white/50">{description}</p>

        <FormInput
          isLoading={attachRefCodeMutation.isPending}
          placeholder="ENTER REFERRAL CODE"
          value={manualRefCode}
          className="mt-5.5"
          onSubmit={handleRefCodeSubmit}
          onChange={(e) => setManualRefCode(e.target.value.trim())}
        />

        <div className="absolute left-0 top-full mt-3 line-clamp-5 w-full text-left text-xs text-red-600">
          {error}
        </div>
      </div>
      <div />
    </Layout>
  );
};
