import { useQuery } from "@tanstack/react-query";
import { twJoin } from "tailwind-merge";

import { NodeCard } from "entities/node";
import { Border, Layout } from "pages/ui/layout";
import { queries } from "shared/api";
import { useAuth } from "shared/providers/auth";
import shardSvg from "shared/ui/icon/svg/shard.svg";
import { Spinner } from "shared/ui/spinner";

import emptyNode from "./assets/empty-node.svg";
import { Preloader } from "./preloader";
import { Balance } from "./ui/balance";

export const Portfolio = () => {
  const { session } = useAuth();
  const balanceQuery = useQuery(queries.balance({ session }));

  const balance = balanceQuery.data?.balance;

  const shards = balance?.nodes.filter((node) => {
    return parseFloat(node.tier_fraction) < 1;
  });
  const nodes = balance?.nodes.filter((node) => {
    return parseFloat(node.tier_fraction) === 1;
  });

  if (balanceQuery.isLoading) return <Preloader />;

  return (
    <Layout contentClasName={twJoin("items-center")}>
      <Border />
      <h2 className="flex text-center text-sm text-smokeyGrey">Node balance</h2>
      <div className="flex gap-x-12">
        {/* NODES */}
        <Balance className="mt-5" value={nodes?.length ?? 0} />

        {/* SHARDS */}
        {Boolean(shards && shards.length > 0) && (
          <Balance
            img={shardSvg}
            className="mt-5"
            value={shards?.length ?? 0}
          />
        )}
      </div>

      {balanceQuery.isPending && <Spinner className="mx-auto size-10" />}

      {balanceQuery.isSuccess &&
        Boolean(balance?.nodes.length && balance.nodes.length > 0) && (
          <div className="mt-7.5 grid grid-cols-2 gap-3 overflow-y-scroll">
            {balanceQuery.data?.balance.nodes.map((node) => {
              const isShard = parseFloat(node.tier_fraction) < 1;
              return (
                <NodeCard
                  key={node.node_id}
                  name={`${isShard ? "Shard" : "Node"} ${node.tier_name}`}
                  img={node.image}
                  fallbackImg={isShard ? shardSvg : undefined}
                />
              );
            })}
          </div>
        )}

      {balanceQuery.isSuccess && !balance?.nodes.length && (
        <div className="my-auto flex w-10/12 flex-col items-center font-minecraft">
          <img className="mb-7.5" src={emptyNode} alt="empty" />
          <p className="text-center text-sm uppercase text-white/50">
            It seems there is nothing in your portfolio yet
          </p>
        </div>
      )}
    </Layout>
  );
};
