import { twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";

import checkSvg from "../assets/check.svg";
import crossSvg from "../assets/cross.svg";
import starSvg from "../assets/star.svg";

type StatusContent = {
  icon: string;
  title: string;
  description?: string;
};

export type StatusType = "pending" | "success" | "error";

const statusMap: Record<StatusType, StatusContent> = {
  pending: {
    icon: starSvg,
    title: "Transaction pending...",
    description: "Waiting for the blockchain to acknowledge our existence...",
  },
  success: {
    icon: checkSvg,
    title: "Transaction successful",
    description: "Congratulations, you received",
  },
  error: {
    icon: crossSvg,
    title: "Transaction failed",
  },
} as const;

type Props = {
  className?: string;
  status: StatusType;

  onLinkClick?: () => void;
};

export const Status = (props: Props) => {
  const { className, status, onLinkClick } = props;
  const { icon, title, description } = statusMap[status];
  return (
    <div className={twMerge("flex flex-col items-center", className)}>
      <img className="h-16" src={icon} />
      <h3
        className="mt-8 inline-flex items-center gap-x-2.5 text-2xl text-white"
        onClick={onLinkClick}
      >
        {title}

        {onLinkClick && status === "error" && (
          <Icon name="link" className="size-4 text-white/30" />
        )}
      </h3>
      {description && (
        <p className="mt-2.5 text-center text-sm text-white/50">
          {description}
        </p>
      )}
    </div>
  );
};
