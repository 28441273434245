import { PropsWithChildren, ReactNode } from "react";

import { twMerge } from "tailwind-merge";

import { Navbar } from "./nav-bar";
import { Title } from "./title";

type Props = {
  title?: ReactNode;
  className?: string;
  contentClasName?: string;
  showNavbar?: boolean;
};

export const Layout = ({
  title,
  className,
  contentClasName,
  children,
  showNavbar = true,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={twMerge(
        "relative flex max-h-full w-full grow flex-col px-5 pt-3.5",
        !showNavbar && "pb-5",
        className
      )}
    >
      {typeof title === "string" ? <Title>{title}</Title> : title}
      <div
        className={twMerge(
          "relative mt-5 flex min-h-0 w-full grow flex-col overflow-hidden px-5 pb-4 pt-5",
          contentClasName
        )}
      >
        {children}
      </div>
      {showNavbar && <Navbar className="sticky bottom-0 mt-auto" />}
    </div>
  );
};
