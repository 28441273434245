import { twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";

type Props = {
  className?: string;
};

export const Separator = ({ className }: Props) => {
  return (
    <div className={twMerge("flex items-center", className)}>
      <div className="h-px w-full bg-darkGrey" />
      <div className="inline-flex items-center justify-center rounded-md border border-darkGrey p-2">
        <Icon name="arrowDown" className="size-2.5 text-white/50" />
      </div>
      <div className="h-px w-full bg-darkGrey" />
    </div>
  );
};
