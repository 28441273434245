import { NavLink } from "react-router-dom";

import { hapticFeedback } from "@telegram-apps/sdk-react";
import { twMerge } from "tailwind-merge";

import AccountIcon from "./assets/account.svg?react";
import BalanceIcon from "./assets/portfolio.svg?react";
import SaleIcon from "./assets/sale.svg?react";

const menuMap = [
  {
    path: "/",
    icon: <SaleIcon className="mb-[6px] size-[23px] fill-current" />,
    label: "Sale",
  },
  {
    path: "/portfolio",
    icon: <BalanceIcon className="mb-[6px] size-[23px] fill-current" />,
    label: "Portfolio",
  },
  {
    path: "/account",
    icon: <AccountIcon className="mb-[6px] size-[23px] fill-current" />,
    label: "Account",
  },
];
type Props = {
  className?: string;
};
export const Navbar = ({ className }: Props) => {
  return (
    <nav
      className={twMerge(
        "flex w-full items-center justify-between px-2.5 py-4",
        className
      )}
    >
      {menuMap.map(({ path, icon, label }) => {
        return (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) => {
              return twMerge(
                "flex w-1/3 cursor-pointer flex-col items-center justify-center text-[10px] font-bold uppercase text-white/50 hover:text-white",
                isActive && "text-white/100 "
              );
            }}
            onClick={() => {
              hapticFeedback.impactOccurred("medium");
            }}
          >
            {icon}
            {label}
          </NavLink>
        );
      })}
    </nav>
  );
};
