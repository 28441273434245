import Skeleton from "react-loading-skeleton";

import { Border, Layout } from "pages/ui/layout";

export const Preloader = () => {
  return (
    <Layout>
      <Border />
      {/* Header */}
      <div className="mb-[20px] flex justify-center">
        <Skeleton className="h-4 w-[88px] rounded" />
      </div>
      <div className="mb-7.5 flex justify-center space-x-[8px]">
        <Skeleton className="size-[40px] rounded-full" />
        <Skeleton className="h-[40px] w-[84px] rounded" />
      </div>
      <div className="flex flex-wrap items-center justify-center gap-[15px]">
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
        <div className="flex h-[160px] w-[132px] flex-col items-center justify-center space-y-[12px] rounded bg-[#1E1E1E]">
        <Skeleton className="size-[80px] rounded-full" />
        <Skeleton className="h-[16px] w-[88px] rounded" />
        </div>
      </div>
      {/* Content */}
      <Skeleton className="mt-8 h-3 w-[50px] rounded" />
      <div className="mt-2 flex items-center justify-between">
        <Skeleton className="h-[42px] w-[167px] rounded-lg" />
        <Skeleton className="h-[31px] w-[100px] rounded-full" />
      </div>
      <Skeleton className="mt-2 h-3 w-[33px] rounded" />

      <div className="mt-2.5 h-px w-full bg-darkGrey opacity-60" />

      {/* Content 2 */}
      <div className="mt-2.5 flex justify-between">
        <Skeleton className="h-3 w-[74px] rounded" />
        <Skeleton className="h-[23px] w-[54px] rounded-full" />
      </div>
      <div className="mt-2 flex items-center justify-between">
        <Skeleton
          baseColor="#F7CD7B24"
          highlightColor="#F7CD7B0B"
          className="h-[42px] w-[167px] rounded-lg"
        />
        <Skeleton className="h-[31px] w-[100px] rounded-full" />
      </div>
      <div className="mt-2 flex justify-between">
        <Skeleton
          baseColor="#F7CD7B24"
          highlightColor="#F7CD7B0B"
          className="h-3 w-[76px] rounded"
        />
        <Skeleton className="h-3 w-[76px] rounded" />
        <Skeleton className="h-3 w-[76px] rounded" />
      </div>

      <div className="mt-2.5 h-px w-full bg-white/10" />

      <div className="mt-2.5 flex justify-between">
        <div className="h-3 w-[76px]" />
        <Skeleton className="h-3 w-[76px] rounded" />
        <Skeleton className="h-3 w-[76px] rounded" />
      </div>

      {/* Button */}
      <div className="mt-auto">
        <Skeleton
          baseColor="#F7CD7B24"
          highlightColor="#F7CD7B0B"
          className="h-12 w-full rounded-lg"
        />
      </div>
    </Layout>
  );
};
