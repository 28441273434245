import { GraphQLClient, RequestOptions } from "graphql-request";
import { GraphQLError, print } from "graphql";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions["requestHeaders"];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Decimal: { input: string; output: string };
  JWT: { input: string; output: string };
};

export type Allocation = {
  __typename?: "Allocation";
  apy: Scalars["Decimal"]["output"];
  available_gnet: TokenAmount;
  available_nodes: Scalars["Int"]["output"];
  closed_until: Maybe<Scalars["DateTime"]["output"]>;
  color: PoolColor;
  cooldownReserve: Maybe<Scalars["Int"]["output"]>;
  fraction: Scalars["Decimal"]["output"];
  implied_fdv: Scalars["Decimal"]["output"];
  max_gnet: TokenAmount;
  max_nodes: Scalars["Int"]["output"];
  min_gnet: TokenAmount;
  min_nodes: Scalars["Int"]["output"];
  node_price_gnet: TokenAmount;
  nodes_images: Array<Scalars["String"]["output"]>;
  price_gnet: Array<TokenAmount>;
  ref_code: Maybe<Scalars["String"]["output"]>;
  status: AllocationStatus;
  tier: Scalars["Int"]["output"];
  tier_name: Scalars["String"]["output"];
  token_price_usd: Array<TokenPriceUsd>;
  total_gnet: TokenAmount;
  total_nodes: Scalars["Int"]["output"];
  ubi: Scalars["Decimal"]["output"];
  validation: Scalars["Decimal"]["output"];
  wave: Scalars["Int"]["output"];
};

export type AllocationStatus =
  | "Active"
  | "Closed"
  | "FullyReserved"
  | "SoldOut";

export type AuthTelegramResponse = {
  __typename?: "AuthTelegramResponse";
  session: Scalars["JWT"]["output"];
  user: User;
};

export type Blockchain = "Ethereum" | "Galactica" | "TON" | "Unknown";

export type ErrorCodes =
  | "AUTHENTICATION_FAILED"
  | "INVALID_REF_CODE"
  | "INVALID_SESSION"
  | "INVALID_TELEGRAM_INIT_DATA"
  | "USER_NOT_FOUND";

export type GnetBalance = {
  __typename?: "GnetBalance";
  amount: Scalars["Decimal"]["output"];
  color: PoolColor;
};

export type Mutation = {
  __typename?: "Mutation";
  /**
   * attachPartnerRefID(session: JWT!, partner_ref_id: String!): User!
   * Requires JWT session, attaches partner ref id to user
   * You can attach partner ref id only once. Next time it will ignore the request
   * Possible error codes:
   * - INVALID_SESSION
   * - USER_NOT_FOUND
   */
  attachPartnerRefID: User;
  /**
   * attachRefCode(session: JWT!, ref_code: String!): User
   * Requires JWT session, attaches ref code to user
   * Possible error codes:
   * - INVALID_SESSION
   * - INVALID_REF_CODE
   * - USER_NOT_FOUND
   * - RATE_LIMIT_EXCEEDED
   */
  attachRefCode: User;
  /**
   * Attach transaction hash to reserve
   * Requires JWT session, reserve proof and transaction hash
   * Returns true if successful
   * Possible error codes:
   * - INVALID_SESSION
   * - RESERVE_NOT_FOUND
   */
  attachTxHashToReservation: Scalars["Boolean"]["output"];
  /**
   * authTelegram(telegram_init_data_raw: String!): AuthTelegramResponse
   * Requires Telegram init data in raw URL-encoded format
   * Returns JWT session and user data
   * Possible error codes:
   * - INVALID_TELEGRAM_INIT_DATA
   * - AUTHENTICATION_FAILED
   */
  authTelegram: AuthTelegramResponse;
  /**
   * cancelReserve(session: JWT!): Boolean
   * Requires JWT session, cancels all user's unpaid reserves
   * Returns true if successful
   * Possible error codes:
   * - INVALID_SESSION
   * - RESERVE_NOT_FOUND
   * - CANCEL_RESERVE_FAILED
   */
  cancelReserve: Scalars["Boolean"]["output"];
  /**
   * reserve(session: JWT!, reserve: ReserveInput!): ReserveResponse
   * Requires JWT session and reserve input
   * Returns reserve JWT session
   * Possible error codes:
   * - INVALID_SESSION
   * - INVALID_RESERVE_AMOUNT
   * - INVALID_PAYMENT_TOKEN
   * - NOT_ENOUGH_GNET
   * - RESERVE_FAILED
   */
  reserve: Reservation;
};

export type MutationAttachPartnerRefIdArgs = {
  partner_ref_id: Scalars["String"]["input"];
  session: Scalars["JWT"]["input"];
};

export type MutationAttachRefCodeArgs = {
  ref_code: Scalars["String"]["input"];
  session: Scalars["JWT"]["input"];
};

export type MutationAttachTxHashToReservationArgs = {
  reservation_proof: Scalars["JWT"]["input"];
  session: Scalars["JWT"]["input"];
  tx_hash: Scalars["String"]["input"];
};

export type MutationAuthTelegramArgs = {
  telegram_init_data_raw: Scalars["String"]["input"];
};

export type MutationCancelReserveArgs = {
  session: Scalars["JWT"]["input"];
};

export type MutationReserveArgs = {
  reserve: ReserveInput;
  session: Scalars["JWT"]["input"];
};

export type NodeBalance = {
  __typename?: "NodeBalance";
  apy: Scalars["Decimal"]["output"];
  color: PoolColor;
  image: Scalars["String"]["output"];
  implied_fdv: Scalars["Decimal"]["output"];
  node_id: Scalars["String"]["output"];
  payment_id: Scalars["String"]["output"];
  tier: Scalars["Int"]["output"];
  tier_fraction: Scalars["Decimal"]["output"];
  tier_name: Scalars["String"]["output"];
  ubi: Scalars["Decimal"]["output"];
  validation: Scalars["Decimal"]["output"];
  wave: Scalars["Int"]["output"];
};

export type PoolColor = "Blue" | "Green" | "Orange";

export type Query = {
  __typename?: "Query";
  /**
   * allocation(session: JWT!): Allocation
   * Requires JWT session, returns user's allocation data
   * Possible error codes:
   * - INVALID_SESSION
   * - USER_NOT_FOUND
   * - INVALID_REF_CODE
   */
  allocation: Allocation;
  /**
   * balance(session: JWT!): UserBalance
   * Requires JWT session, returns user's balance data
   * Possible error codes:
   * - INVALID_SESSION
   * - USER_NOT_FOUND
   */
  balance: UserBalance;
  /**
   * payment(session: JWT!, reservation_proof: JWT!): ReservationPayment
   * Requires JWT session and reservation proof, returns payment data
   * Possible error codes:
   * - INVALID_SESSION
   * - RESERVE_NOT_FOUND
   * - PAYMENT_NOT_FOUND
   */
  payment: Maybe<ReservationPayment>;
  /**
   * superlink(session: JWT!, code: String!): Superlink!
   * Requires superlink code, returns allocations data
   * Possible error codes:
   * - INVALID_SESSION
   * - SUPERLINK_NOT_FOUND
   */
  superlink: Array<Allocation>;
  /**
   * unpaidReserve(session: JWT!): ReserveResponse
   * Requires JWT session, returns user's unpaid reserve data
   * Possible error codes:
   * - INVALID_SESSION
   * - USER_NOT_FOUND
   * - RESERVE_NOT_FOUND
   */
  unpaidReserve: Maybe<Reservation>;
  /**
   * user(session: JWT!): User
   * Requires JWT session, returns user data
   * Possible error codes:
   * - INVALID_SESSION
   * - USER_NOT_FOUND
   */
  user: User;
};

export type QueryAllocationArgs = {
  session: Scalars["JWT"]["input"];
};

export type QueryBalanceArgs = {
  session: Scalars["JWT"]["input"];
};

export type QueryPaymentArgs = {
  reservation_proof: Scalars["JWT"]["input"];
  session: Scalars["JWT"]["input"];
};

export type QuerySuperlinkArgs = {
  code: Scalars["String"]["input"];
  session: Scalars["JWT"]["input"];
};

export type QueryUnpaidReserveArgs = {
  session: Scalars["JWT"]["input"];
};

export type QueryUserArgs = {
  session: Scalars["JWT"]["input"];
};

export type Reservation = {
  __typename?: "Reservation";
  color: PoolColor;
  gnet_amount: Scalars["Decimal"]["output"];
  nodes: Scalars["Int"]["output"];
  nodes_images: Array<Scalars["String"]["output"]>;
  payment_token: TokenAmount;
  reservation_proof: Scalars["JWT"]["output"];
  tier: Scalars["Int"]["output"];
  tier_fraction: Scalars["Decimal"]["output"];
  tx_hash: Maybe<Scalars["String"]["output"]>;
  valid_until: Scalars["DateTime"]["output"];
  wave: Scalars["Int"]["output"];
};

export type ReservationPayment = {
  __typename?: "ReservationPayment";
  block_number: Scalars["String"]["output"];
  blockchain: Scalars["String"]["output"];
  confirmations: Scalars["Int"]["output"];
  decline_reason: Maybe<Scalars["String"]["output"]>;
  is_accepted: Scalars["Boolean"]["output"];
  is_declined: Scalars["Boolean"]["output"];
  payment_token: TokenAmount;
  receiver_address: Scalars["String"]["output"];
  tx_hash: Scalars["String"]["output"];
};

export type ReserveInput = {
  amount_gnet: Scalars["Decimal"]["input"];
  payment_token_id: Scalars["String"]["input"];
};

export type TokenAmount = {
  __typename?: "TokenAmount";
  amount: Scalars["Decimal"]["output"];
  meta: TokenMeta;
};

export type TokenMeta = {
  __typename?: "TokenMeta";
  blockchain: Scalars["String"]["output"];
  contract_address: Maybe<Scalars["String"]["output"]>;
  decimals: Scalars["Int"]["output"];
  icon: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  payment_address: Maybe<Scalars["String"]["output"]>;
  symbol: Scalars["String"]["output"];
  token_id: Scalars["String"]["output"];
  type: TokenType;
};

export type TokenPriceUsd = {
  __typename?: "TokenPriceUSD";
  price_usd: Scalars["Decimal"]["output"];
  token_id: Scalars["String"]["output"];
};

export type TokenType = "ERC20" | "Jetton" | "Native";

export type User = {
  __typename?: "User";
  created_at: Scalars["DateTime"]["output"];
  gala_address: Maybe<Scalars["String"]["output"]>;
  ref_code: Maybe<Scalars["String"]["output"]>;
  telegram_id: Maybe<Scalars["ID"]["output"]>;
  telegram_username: Maybe<Scalars["String"]["output"]>;
};

export type UserBalance = {
  __typename?: "UserBalance";
  gnet: Array<GnetBalance>;
  gnet_total: Scalars["Decimal"]["output"];
  nodes: Array<NodeBalance>;
  payments: Array<UserPayment>;
};

export type UserPayment = {
  __typename?: "UserPayment";
  block_number: Scalars["String"]["output"];
  blockchain: Scalars["String"]["output"];
  color: PoolColor;
  confirmations: Scalars["Int"]["output"];
  created_at: Scalars["DateTime"]["output"];
  decline_reason: Maybe<Scalars["String"]["output"]>;
  gnet_amount: Scalars["Decimal"]["output"];
  is_accepted: Scalars["Boolean"]["output"];
  is_declined: Scalars["Boolean"]["output"];
  nodes: Scalars["Int"]["output"];
  payment_id: Scalars["String"]["output"];
  payment_token: TokenAmount;
  receiver_address: Scalars["String"]["output"];
  tier: Scalars["Int"]["output"];
  tier_fraction: Scalars["Decimal"]["output"];
  tier_name: Scalars["String"]["output"];
  tx_hash: Scalars["String"]["output"];
  wave: Scalars["Int"]["output"];
};

export type AttachPartnerRefIdMutationVariables = Exact<{
  session: Scalars["JWT"]["input"];
  partner_ref_id: Scalars["String"]["input"];
}>;

export type AttachPartnerRefIdMutation = {
  __typename?: "Mutation";
  attachPartnerRefID: {
    __typename?: "User";
    telegram_id: string | null;
    telegram_username: string | null;
    ref_code: string | null;
    gala_address: string | null;
    created_at: string;
  };
};

export type AttachRefCodeMutationVariables = Exact<{
  session: Scalars["JWT"]["input"];
  refCode: Scalars["String"]["input"];
}>;

export type AttachRefCodeMutation = {
  __typename?: "Mutation";
  attachRefCode: {
    __typename?: "User";
    telegram_id: string | null;
    telegram_username: string | null;
    ref_code: string | null;
    gala_address: string | null;
    created_at: string;
  };
};

export type AttachTxHashToReservationMutationVariables = Exact<{
  session: Scalars["JWT"]["input"];
  reservation_proof: Scalars["JWT"]["input"];
  tx_hash: Scalars["String"]["input"];
}>;

export type AttachTxHashToReservationMutation = {
  __typename?: "Mutation";
  attachTxHashToReservation: boolean;
};

export type AuthTelegramMutationVariables = Exact<{
  telegramInitDataRaw: Scalars["String"]["input"];
}>;

export type AuthTelegramMutation = {
  __typename?: "Mutation";
  authTelegram: {
    __typename?: "AuthTelegramResponse";
    session: string;
    user: {
      __typename?: "User";
      telegram_id: string | null;
      telegram_username: string | null;
      ref_code: string | null;
      gala_address: string | null;
      created_at: string;
    };
  };
};

export type CancelReserveMutationVariables = Exact<{
  session: Scalars["JWT"]["input"];
}>;

export type CancelReserveMutation = {
  __typename?: "Mutation";
  cancelReserve: boolean;
};

export type ReserveMutationVariables = Exact<{
  session: Scalars["JWT"]["input"];
  reserve: ReserveInput;
}>;

export type ReserveMutation = {
  __typename?: "Mutation";
  reserve: {
    __typename?: "Reservation";
    gnet_amount: string;
    nodes: number;
    reservation_proof: string;
    valid_until: string;
    tx_hash: string | null;
    nodes_images: Array<string>;
    color: PoolColor;
    wave: number;
    tier: number;
    tier_fraction: string;
    payment_token: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
  };
};

export type GetAllocationQueryVariables = Exact<{
  session: Scalars["JWT"]["input"];
}>;

export type GetAllocationQuery = {
  __typename?: "Query";
  allocation: {
    __typename?: "Allocation";
    color: PoolColor;
    tier: number;
    tier_name: string;
    wave: number;
    status: AllocationStatus;
    closed_until: string | null;
    total_nodes: number;
    available_nodes: number;
    max_nodes: number;
    min_nodes: number;
    nodes_images: Array<string>;
    cooldownReserve: number | null;
    implied_fdv: string;
    ubi: string;
    validation: string;
    apy: string;
    fraction: string;
    total_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    available_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    node_price_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    price_gnet: Array<{
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    }>;
    min_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    max_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    token_price_usd: Array<{
      __typename?: "TokenPriceUSD";
      token_id: string;
      price_usd: string;
    }>;
  };
};

export type GetBalanceQueryVariables = Exact<{
  session: Scalars["JWT"]["input"];
}>;

export type GetBalanceQuery = {
  __typename?: "Query";
  balance: {
    __typename?: "UserBalance";
    gnet_total: string;
    gnet: Array<{
      __typename?: "GnetBalance";
      amount: string;
      color: PoolColor;
    }>;
    nodes: Array<{
      __typename?: "NodeBalance";
      node_id: string;
      payment_id: string;
      color: PoolColor;
      tier: number;
      tier_name: string;
      wave: number;
      image: string;
      implied_fdv: string;
      ubi: string;
      validation: string;
      apy: string;
      tier_fraction: string;
    }>;
    payments: Array<{
      __typename?: "UserPayment";
      payment_id: string;
      blockchain: string;
      tx_hash: string;
      receiver_address: string;
      block_number: string;
      nodes: number;
      gnet_amount: string;
      color: PoolColor;
      tier: number;
      tier_name: string;
      wave: number;
      is_accepted: boolean;
      is_declined: boolean;
      confirmations: number;
      decline_reason: string | null;
      created_at: string;
      tier_fraction: string;
      payment_token: {
        __typename?: "TokenAmount";
        amount: string;
        meta: {
          __typename?: "TokenMeta";
          blockchain: string;
          contract_address: string | null;
          decimals: number;
          name: string;
          payment_address: string | null;
          symbol: string;
          token_id: string;
          type: TokenType;
          icon: string;
        };
      };
    }>;
  };
};

export type GetPaymentQueryVariables = Exact<{
  session: Scalars["JWT"]["input"];
  reservation_proof: Scalars["JWT"]["input"];
}>;

export type GetPaymentQuery = {
  __typename?: "Query";
  payment: {
    __typename?: "ReservationPayment";
    tx_hash: string;
    block_number: string;
    blockchain: string;
    receiver_address: string;
    confirmations: number;
    is_accepted: boolean;
    is_declined: boolean;
    decline_reason: string | null;
    payment_token: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
  } | null;
};

export type GetSuperlinkQueryVariables = Exact<{
  session: Scalars["JWT"]["input"];
  code: Scalars["String"]["input"];
}>;

export type GetSuperlinkQuery = {
  __typename?: "Query";
  superlink: Array<{
    __typename?: "Allocation";
    color: PoolColor;
    tier: number;
    tier_name: string;
    wave: number;
    status: AllocationStatus;
    ref_code: string | null;
    total_nodes: number;
    available_nodes: number;
    max_nodes: number;
    nodes_images: Array<string>;
    cooldownReserve: number | null;
    implied_fdv: string;
    ubi: string;
    validation: string;
    apy: string;
    total_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    available_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    node_price_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    price_gnet: Array<{
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    }>;
    min_gnet: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
    token_price_usd: Array<{
      __typename?: "TokenPriceUSD";
      token_id: string;
      price_usd: string;
    }>;
  }>;
};

export type GetUnpaidReserveQueryVariables = Exact<{
  session: Scalars["JWT"]["input"];
}>;

export type GetUnpaidReserveQuery = {
  __typename?: "Query";
  unpaidReserve: {
    __typename?: "Reservation";
    gnet_amount: string;
    nodes: number;
    tier_fraction: string;
    reservation_proof: string;
    valid_until: string;
    tx_hash: string | null;
    nodes_images: Array<string>;
    color: PoolColor;
    wave: number;
    tier: number;
    payment_token: {
      __typename?: "TokenAmount";
      amount: string;
      meta: {
        __typename?: "TokenMeta";
        blockchain: string;
        contract_address: string | null;
        decimals: number;
        name: string;
        payment_address: string | null;
        symbol: string;
        token_id: string;
        type: TokenType;
        icon: string;
      };
    };
  } | null;
};

export type GetUserQueryVariables = Exact<{
  session: Scalars["JWT"]["input"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    telegram_id: string | null;
    telegram_username: string | null;
    ref_code: string | null;
    gala_address: string | null;
    created_at: string;
  };
};

export const AttachPartnerRefIdDocument = gql`
  mutation AttachPartnerRefID($session: JWT!, $partner_ref_id: String!) {
    attachPartnerRefID(session: $session, partner_ref_id: $partner_ref_id) {
      telegram_id
      telegram_username
      ref_code
      gala_address
      created_at
    }
  }
`;
export const AttachRefCodeDocument = gql`
  mutation AttachRefCode($session: JWT!, $refCode: String!) {
    attachRefCode(session: $session, ref_code: $refCode) {
      telegram_id
      telegram_username
      ref_code
      gala_address
      created_at
    }
  }
`;
export const AttachTxHashToReservationDocument = gql`
  mutation AttachTxHashToReservation(
    $session: JWT!
    $reservation_proof: JWT!
    $tx_hash: String!
  ) {
    attachTxHashToReservation(
      session: $session
      reservation_proof: $reservation_proof
      tx_hash: $tx_hash
    )
  }
`;
export const AuthTelegramDocument = gql`
  mutation AuthTelegram($telegramInitDataRaw: String!) {
    authTelegram(telegram_init_data_raw: $telegramInitDataRaw) {
      session
      user {
        telegram_id
        telegram_username
        ref_code
        gala_address
        created_at
      }
    }
  }
`;
export const CancelReserveDocument = gql`
  mutation CancelReserve($session: JWT!) {
    cancelReserve(session: $session)
  }
`;
export const ReserveDocument = gql`
  mutation Reserve($session: JWT!, $reserve: ReserveInput!) {
    reserve(session: $session, reserve: $reserve) {
      gnet_amount
      nodes
      payment_token {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      reservation_proof
      valid_until
      tx_hash
      nodes_images
      color
      wave
      tier
      tier_fraction
    }
  }
`;
export const GetAllocationDocument = gql`
  query GetAllocation($session: JWT!) {
    allocation(session: $session) {
      color
      tier
      tier_name
      wave
      status
      closed_until
      total_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      available_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      total_nodes
      available_nodes
      node_price_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      price_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      max_nodes
      min_nodes
      min_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      max_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      nodes_images
      cooldownReserve
      token_price_usd {
        token_id
        price_usd
      }
      implied_fdv
      ubi
      validation
      apy
      fraction
    }
  }
`;
export const GetBalanceDocument = gql`
  query GetBalance($session: JWT!) {
    balance(session: $session) {
      gnet_total
      gnet {
        amount
        color
      }
      nodes {
        node_id
        payment_id
        color
        tier
        tier_name
        wave
        image
        implied_fdv
        ubi
        validation
        apy
        tier_fraction
      }
      payments {
        payment_id
        blockchain
        tx_hash
        receiver_address
        block_number
        nodes
        gnet_amount
        color
        tier
        tier_name
        wave
        payment_token {
          amount
          meta {
            blockchain
            contract_address
            decimals
            name
            payment_address
            symbol
            token_id
            type
            icon
          }
        }
        is_accepted
        is_declined
        confirmations
        decline_reason
        created_at
        tier_fraction
      }
    }
  }
`;
export const GetPaymentDocument = gql`
  query GetPayment($session: JWT!, $reservation_proof: JWT!) {
    payment(session: $session, reservation_proof: $reservation_proof) {
      tx_hash
      payment_token {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      block_number
      blockchain
      receiver_address
      confirmations
      is_accepted
      is_declined
      decline_reason
    }
  }
`;
export const GetSuperlinkDocument = gql`
  query GetSuperlink($session: JWT!, $code: String!) {
    superlink(session: $session, code: $code) {
      color
      tier
      tier_name
      wave
      status
      ref_code
      total_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      available_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      total_nodes
      available_nodes
      node_price_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      price_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      max_nodes
      min_gnet {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      nodes_images
      cooldownReserve
      token_price_usd {
        token_id
        price_usd
      }
      implied_fdv
      ubi
      validation
      apy
    }
  }
`;
export const GetUnpaidReserveDocument = gql`
  query GetUnpaidReserve($session: JWT!) {
    unpaidReserve(session: $session) {
      gnet_amount
      nodes
      tier_fraction
      payment_token {
        amount
        meta {
          blockchain
          contract_address
          decimals
          name
          payment_address
          symbol
          token_id
          type
          icon
        }
      }
      reservation_proof
      valid_until
      tx_hash
      nodes_images
      color
      wave
      tier
    }
  }
`;
export const GetUserDocument = gql`
  query GetUser($session: JWT!) {
    user(session: $session) {
      telegram_id
      telegram_username
      ref_code
      gala_address
      created_at
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();
const AttachPartnerRefIdDocumentString = print(AttachPartnerRefIdDocument);
const AttachRefCodeDocumentString = print(AttachRefCodeDocument);
const AttachTxHashToReservationDocumentString = print(
  AttachTxHashToReservationDocument,
);
const AuthTelegramDocumentString = print(AuthTelegramDocument);
const CancelReserveDocumentString = print(CancelReserveDocument);
const ReserveDocumentString = print(ReserveDocument);
const GetAllocationDocumentString = print(GetAllocationDocument);
const GetBalanceDocumentString = print(GetBalanceDocument);
const GetPaymentDocumentString = print(GetPaymentDocument);
const GetSuperlinkDocumentString = print(GetSuperlinkDocument);
const GetUnpaidReserveDocumentString = print(GetUnpaidReserveDocument);
const GetUserDocumentString = print(GetUserDocument);
export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    AttachPartnerRefID(
      variables: AttachPartnerRefIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: AttachPartnerRefIdMutation;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AttachPartnerRefIdMutation>(
            AttachPartnerRefIdDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AttachPartnerRefID",
        "mutation",
        variables,
      );
    },
    AttachRefCode(
      variables: AttachRefCodeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: AttachRefCodeMutation;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AttachRefCodeMutation>(
            AttachRefCodeDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AttachRefCode",
        "mutation",
        variables,
      );
    },
    AttachTxHashToReservation(
      variables: AttachTxHashToReservationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: AttachTxHashToReservationMutation;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AttachTxHashToReservationMutation>(
            AttachTxHashToReservationDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AttachTxHashToReservation",
        "mutation",
        variables,
      );
    },
    AuthTelegram(
      variables: AuthTelegramMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: AuthTelegramMutation;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AuthTelegramMutation>(
            AuthTelegramDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AuthTelegram",
        "mutation",
        variables,
      );
    },
    CancelReserve(
      variables: CancelReserveMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: CancelReserveMutation;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CancelReserveMutation>(
            CancelReserveDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "CancelReserve",
        "mutation",
        variables,
      );
    },
    Reserve(
      variables: ReserveMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: ReserveMutation;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ReserveMutation>(ReserveDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Reserve",
        "mutation",
        variables,
      );
    },
    GetAllocation(
      variables: GetAllocationQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: GetAllocationQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetAllocationQuery>(
            GetAllocationDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetAllocation",
        "query",
        variables,
      );
    },
    GetBalance(
      variables: GetBalanceQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: GetBalanceQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetBalanceQuery>(
            GetBalanceDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetBalance",
        "query",
        variables,
      );
    },
    GetPayment(
      variables: GetPaymentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: GetPaymentQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetPaymentQuery>(
            GetPaymentDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetPayment",
        "query",
        variables,
      );
    },
    GetSuperlink(
      variables: GetSuperlinkQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: GetSuperlinkQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetSuperlinkQuery>(
            GetSuperlinkDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetSuperlink",
        "query",
        variables,
      );
    },
    GetUnpaidReserve(
      variables: GetUnpaidReserveQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: GetUnpaidReserveQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetUnpaidReserveQuery>(
            GetUnpaidReserveDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetUnpaidReserve",
        "query",
        variables,
      );
    },
    GetUser(
      variables: GetUserQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<{
      data: GetUserQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetUserQuery>(GetUserDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetUser",
        "query",
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
