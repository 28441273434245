import { PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

import { Icon } from "../icon";
import ctComIconSvg from "./ct-com-icon.svg";

type Props = {
  className?: string;
};

export const Content = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <>
      <img
        src={ctComIconSvg}
        className="absolute left-1/2 top-1/2 z-0 size-14 -translate-x-1/2 -translate-y-1/2"
      />
      <span
        className={twMerge(
          "relative z-10 inline-flex w-full justify-center rounded p-2 text-center inner-border inner-border-black/20",
          "after:absolute after:bottom-2 after:left-0 after:inline-flex after:h-3 after:w-px after:bg-black",
          className
        )}
      >
        <Icon
          name="dots"
          className="absolute right-1 top-1 size-2.5 opacity-30"
        />
        {children}
      </span>
    </>
  );
};
