import { useMutation } from "@tanstack/react-query";

import type { ReserveInput } from "../graphql";

import { useAuth } from "../providers/auth";
import { sdk } from "../providers/graphql-client";

export function useReserveMutation() {
  const auth = useAuth();

  return useMutation({
    mutationFn: async (variables: ReserveInput) => {
      const res = await sdk.Reserve({
        session: auth.session,
        reserve: variables,
      });

      if (res.errors) {
        throw res.errors;
      }

      return res;
    },
  });
}
