import { twMerge } from "tailwind-merge";

import nodeSvg from "shared/ui/icon/svg/node.svg";
import { Img } from "shared/ui/img";

type Props = {
  className?: string;
  value: number;
  img?: string;
};

export const Balance = (props: Props) => {
  const { className, value, img = nodeSvg } = props;

  return (
    <div className={twMerge("flex items-center gap-x-2.5", className)}>
      <Img className={"size-10 shrink-0"} src={img} alt="" />
      <span className="text-[42px] text-white">{value}</span>
    </div>
  );
};
