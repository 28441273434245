import { darkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";

import { Web } from "./web";

export const Page = () => {
  return (
    <RainbowKitProvider theme={darkTheme()}>
      <Web />
    </RainbowKitProvider>
  );
};
