import { useCallback } from "react";

import { useSDK } from "@metamask/sdk-react";
import { hapticFeedback } from "@telegram-apps/sdk-react";
import { useTonConnectUI } from "@tonconnect/ui-react";

import { Blockchain } from "shared/graphql";

export function useDisconnect(
  blockchain: Omit<string, Blockchain> | Blockchain
) {
  const { sdk } = useSDK();
  const [tonConnectUI] = useTonConnectUI();

  return useCallback(async () => {
    hapticFeedback.impactOccurred("medium");
    try {
      if (blockchain === "TON") {
        return await tonConnectUI.disconnect();
      }
      await sdk?.terminate();
    } catch (error) {
      console.error(error);
    }
  }, [tonConnectUI, sdk, blockchain]);
}
