import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useEventCallback } from "usehooks-ts";

import { NodesRows } from "entities/node";
import { TxStatus, TxStatusType } from "entities/tx";
import { Border, Layout } from "pages/ui/layout";
import { Reservation, ReservationPayment } from "shared/graphql";
import { Button } from "shared/ui/button";
import shardSvg from "shared/ui/icon/svg/shard.svg";
import { plural } from "shared/utils";

type Props = {
  isShard: boolean;
  title: string;
  payment: ReservationPayment | undefined | null;
  reservation: Reservation;
  onMount: () => void;
};

function getStatus(
  payment: ReservationPayment | undefined | null
): TxStatusType {
  if (payment?.is_declined) return "error";
  if (payment?.is_accepted) return "success";
  return "pending";
}

export const Payment = (props: Props) => {
  const { payment, reservation, title, isShard, onMount } = props;

  const status = getStatus(payment);

  const mountEvent = useEventCallback(onMount);

  useEffect(() => {
    mountEvent();
  }, [mountEvent]);

  return (
    <Layout title="BLUWHALE NODE SALE">
      <Border />
      <h2 className="w-full text-center">{title}</h2>

      <TxStatus className="mt-9.5" status={status} />

      <NodesRows
        label={
          isShard
            ? plural(reservation.nodes, ["SHARD", "SHARDS", "SHARDS"])
            : plural(reservation.nodes, ["NODE", "NODES", "NODES"])
        }
        className="mt-7.5"
        img={isShard ? shardSvg : undefined}
        count={reservation.nodes}
      />

      {(status === "error" || status === "success") && (
        <Button
          className="mb-[8px] mt-auto"
          as={Link}
          to={status === "error" ? "/" : "/portfolio"}
        >
          <Button.Content>
            {status === "error" ? "Back to Sale" : "Check Portfolio"}
          </Button.Content>
        </Button>
      )}
    </Layout>
  );
};
