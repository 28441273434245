import { useState } from "react";

import { User } from "@telegram-apps/sdk-react";
import { DateTime } from "luxon";
import { twJoin } from "tailwind-merge";
import { useCopyToClipboard } from "usehooks-ts";

import { Border, Layout } from "pages/ui/layout";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";

type Props = {
  showNavbar?: boolean;
  error: unknown;
  user?: User;
};

export const ErrorComponent = ({ showNavbar = true, error, user }: Props) => {
  const [time] = useState(
    DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
  );
  console.error(error);

  const [copiedValue, copy] = useCopyToClipboard();

  return (
    <Layout showNavbar={showNavbar} contentClasName={twJoin("justify-between")}>
      <Border type="withBottomShadow" />
      <div />
      <div className="flex flex-col px-7 text-center">
        <h1 className="text-center text-2xl">Oops! Something went wrong...</h1>
        <p className="mt-4 text-center text-sm text-white/50">
          See below for technical details of&nbsp;the&nbsp;error.
        </p>
      </div>

      <div className="flex flex-col rounded-md bg-night p-2.5">
        <div className="mb-2.5 flex items-center text-xs">
          <h3 className="text-xs uppercase text-white/25">technical details</h3>
          <Link
            className="ml-auto flex shrink-0 items-center gap-x-1"
            as="button"
            onClick={() => error instanceof Error && copy(error.message)}
          >
            COPY{" "}
            <Icon name={copiedValue ? "check" : "copy"} className="size-3" />
          </Link>
        </div>

        <ul className="flex flex-col text-xs">
          {user && (
            <>
              <li className="flex items-center justify-between">
                <span className="text-white/50">Telegram ID</span>
                <span>{user.id}</span>
              </li>
              <li className="flex items-center justify-between">
                <span className="text-white/50">USER</span>
                <span>{user?.username}</span>
              </li>
            </>
          )}

          <li className="flex items-center justify-between">
            <span className="text-white/50">Date and Time</span>
            <span>{time}</span>
          </li>
          <li className="flex items-center justify-between">
            <span className="shrink-0 text-white/50">Issue type</span>
            <span className="ml-1 truncate">
              {error instanceof Error && error.message}
            </span>
          </li>
        </ul>
      </div>
    </Layout>
  );
};
