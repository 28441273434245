import BorderWithBottomShadow from "./assets/border-with-bottom-shadow.svg?react";
import BottomPattern from "./assets/bottom-pattern.svg?react";

type Props = {
  type?: "big" | "small" | "withBottomShadow";
};

export const Border = ({ type = "small" }: Props) => {
  if (type === "withBottomShadow") {
    return (
      <>
        <BorderWithBottomShadow
          preserveAspectRatio="none"
          className="absolute inset-0 -z-20 size-full"
        />
        <BottomPattern className="absolute inset-x-0 -bottom-2 -z-10 w-full" />
      </>
    );
  }

  if (type === "big") {
    return (
      <svg
        className="absolute inset-0 -z-20 size-full"
        preserveAspectRatio="none"
        width="346"
        height="688"
        viewBox="0 0 346 688"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M24.8761 687.4C11.695 687.4 1.106 676.612 1.41924 663.439C2.65731 611.371 5.31881 483.255 5.3188 344.087C5.3188 204.92 2.65729 76.8042 1.41921 24.7358C1.10597 11.5624 11.695 0.77478 24.8761 0.774779L321.111 0.774766C334.297 0.774766 344.888 11.5691 344.572 24.7471C343.33 76.3904 340.681 203.078 340.681 344.087C340.681 485.097 343.33 611.784 344.572 663.428C344.888 676.606 334.297 687.4 321.111 687.4L24.8761 687.4Z"
          stroke="url(#paint0_linear_1512_2271)"
          strokeWidth="1.2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1512_2271"
            x1="313.263"
            y1="-3.71393"
            x2="313.263"
            y2="691.889"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.327968" stopColor="white" stopOpacity="0.12" />
            <stop offset="0.722294" stopColor="white" stopOpacity="0.12" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="346"
      height="571"
      className="absolute inset-0 -z-20 size-full"
      preserveAspectRatio="none"
      viewBox="0 0 346 571"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M25.0179 570.3C11.7856 570.3 1.17185 559.392 1.54353 546.165C2.82653 500.508 5.31881 397.451 5.31881 285.814C5.3188 174.177 2.82651 71.1199 1.54351 25.4633C1.17183 12.2366 11.7856 1.32776 25.018 1.32776L320.967 1.32774C334.205 1.32774 344.822 12.2373 344.446 25.4692C343.16 70.7532 340.681 172.728 340.681 285.814C340.681 398.901 343.16 500.875 344.446 546.159C344.822 559.391 334.205 570.3 320.967 570.3L25.0179 570.3Z"
        stroke="url(#paint0_linear_1512_3265)"
        strokeWidth="1.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1512_3265"
          x1="313.263"
          y1="-2.49579"
          x2="313.263"
          y2="574.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.327968" stopColor="white" stopOpacity="0.12" />
          <stop offset="0.722294" stopColor="white" stopOpacity="0.12" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
