import { ChangeEventHandler, Fragment, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { DateTime } from "luxon";
import { twJoin } from "tailwind-merge";

import { TxHistoryRow } from "entities/tx";
import { Border, Layout } from "pages/ui/layout";
import { queries } from "shared/api";
import { useAuth } from "shared/providers/auth";
import { Icon } from "shared/ui/icon";
import { Input } from "shared/ui/input";
import { groupBy, plural } from "shared/utils";

import emptyTransactions from "./assets/empty-transactions.svg";
import { Preloader } from "./ui/preloader";
import { Separator } from "./ui/separator";
import { getPaymentHistoryStatus } from "./utils/get-payment-status";

export const Account = () => {
  const { session } = useAuth();
  const [inputAddress, setInputAddress] = useState("");

  const query = useQuery(queries.balance({ session }));
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;

    setInputAddress(value.trim());
  };

  const handleSubmit = () => {};

  const paymentsGroups = useMemo(() => {
    const payments = query.data?.balance.payments ?? [];

    const grouped = groupBy(payments, ({ created_at }) => {
      const jsDate = new Date(created_at);
      const isSameYear = jsDate.getFullYear() === new Date().getFullYear();

      const key = DateTime.fromJSDate(jsDate).toLocaleString({
        month: "long",
        day: "numeric",
        year: isSameYear ? undefined : "numeric",
      });

      return key;
    });

    return Object.entries(grouped);
  }, [query.data?.balance.payments]);

  if (query.isPending) return <Preloader />;

  return (
    <Layout>
      <Border />
      <h2 className="text-sm">Connect your address</h2>
      <form
        onSubmit={handleSubmit}
        className="relative mb-7.5 mt-3.5 flex flex-col"
      >
        <Input value={inputAddress} onChange={handleChange} disabled={true} />

        <button
          className="absolute right-4 top-1/2 -translate-y-1/2"
          type="submit"
        >
          <Icon name="arrowRight" className="size-3.5 text-white/50" />
        </button>
      </form>
      {!query.data?.balance.payments.length ? (
        <div className="m-auto flex w-10/12 translate-y-[-35px] flex-col items-center font-minecraft">
          <img className="mb-7.5" src={emptyTransactions} alt="emptytx" />
          <p className="text-center text-sm uppercase text-white/50">
            You don’t have any transactions yet
          </p>
        </div>
      ) : (
        <div className="h-full min-h-0 overflow-y-scroll">
          {paymentsGroups.map(([date, payments], index) => {
            return (
              <Fragment key={date}>
                <Separator
                  className={twJoin(index !== 0 && "mt-7.5")}
                  date={date}
                />
                {payments?.map((payment) => {
                  const {
                    blockchain,
                    nodes,
                    payment_token,
                    tx_hash,
                    tier_fraction,
                  } = payment;
                  const status = getPaymentHistoryStatus(payment);

                  const isShard = parseFloat(tier_fraction) < 1;

                  return (
                    <TxHistoryRow
                      txHash={tx_hash}
                      nodes={nodes}
                      label={
                        isShard
                          ? plural(nodes, ["Shard", "Shards", "Shards"])
                          : plural(nodes, ["Node", "Nodes", "Nodes"])
                      }
                      paymentAmount={new Decimal(payment.payment_token.amount)
                        .div(
                          new Decimal(10).pow(
                            payment.payment_token.meta.decimals
                          )
                        )
                        .toString()}
                      paymentTokenSymbol={payment_token.meta.symbol}
                      blockchain={blockchain}
                      className="mt-7.5"
                      status={status}
                    />
                  );
                })}
              </Fragment>
            );
          })}
        </div>
      )}
    </Layout>
  );
};
