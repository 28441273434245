import { Border } from "pages/ui/layout";
import { Icon } from "shared/ui/icon";
import { Modal as M } from "shared/ui/modal";

import { type Option as OptionType } from "../types";
import { Option } from "./option";

type Props = {
  selectedSymbol: string;
  blockchain: string;
  options: OptionType[];
  onSelect: (option: OptionType) => void;
  onClose: () => void;
};

export const Modal = (props: Props) => {
  const { options, selectedSymbol, blockchain, onSelect, onClose } = props;

  return (
    <M onClose={onClose}>
      <M.Overlay
        className="absolute z-20 bg-onyx px-5 py-2 backdrop-blur-none"
        style={{
          backgroundImage: `url(/bg-stars.png)`,
        }}
      >
        <M.Content className="m-0 overflow-scroll">
          <button
            onClick={onClose}
            className="mt-5 flex items-center gap-1 font-semibold uppercase text-white outline-none transition-colors active:text-goldenrod"
          >
            <Icon name="arrowLeft" className="size-4 text-goldenrod" />
            back
          </button>
          <ul className="mt-5 flex flex-col">
            {options.map((option) => {
              return (
                <Option
                  onClick={() => {
                    onSelect(option);
                  }}
                  key={`${option.symbol}/${option.blockchain}`}
                  isSelected={
                    selectedSymbol === option.symbol &&
                    blockchain === option.blockchain
                  }
                  option={option}
                />
              );
            })}
          </ul>
          <Border />
        </M.Content>
      </M.Overlay>
    </M>
  );
};
