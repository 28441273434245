import { ComponentProps, ReactNode } from "react";

import { useConnectModal } from "@rainbow-me/rainbowkit";
import { twMerge } from "tailwind-merge";
import { useAccount, useDisconnect, useSwitchChain } from "wagmi";

import { Button, ButtonProps } from "shared/ui/button";
import { Link } from "shared/ui/link";
import { chainMap } from "shared/web3/consts";

type Props = {
  blockchain: string;
  connectContent?: ReactNode;
  installMetamaskContent?: ReactNode;
  installSnapContent?: ReactNode;
  switchChainContent?: ReactNode;
  className?: string;
} & ButtonProps;

export const WebButton = (props: Props) => {
  const {
    blockchain,
    className,
    connectContent = "Connect Wallet",
    switchChainContent = "Switch Chain",
  } = props;
  const { chainId } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { switchChain } = useSwitchChain();
  const { disconnect } = useDisconnect();
  const { isDisconnected, isConnecting } = useAccount();
  const targetChainId = chainMap[blockchain];

  const btnProps: ComponentProps<typeof Button> = {
    className: twMerge("gap-x-1.5 whitespace-nowrap", className),
    ...props,
  };

  if (isDisconnected || isConnecting) {
    return (
      <Button {...btnProps} isLoading={isConnecting} onClick={openConnectModal}>
        <Button.Content>{connectContent}</Button.Content>
      </Button>
    );
  }

  if (targetChainId !== chainId) {
    return (
      <>
        <Button
          {...btnProps}
          onClick={() => {
            switchChain({ chainId: Number(targetChainId.toString()) });
          }}
        >
          <Button.Content>{switchChainContent}</Button.Content>
        </Button>
        <Link as="button" className="mt-3" onClick={() => disconnect()}>
          Disconnect wallet
        </Link>
      </>
    );
  }

  return (
    <Link
      {...btnProps}
      as="button"
      className={twMerge("mt-3", btnProps.className)}
      onClick={() => disconnect()}
    >
      Disconnect wallet
    </Link>
  );
};
