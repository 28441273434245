import { Option } from "features/select-currency";
import { Allocation } from "shared/graphql";

export function getCurrencySelectorOptions(allocation: Allocation) {
  return allocation.price_gnet.map(({ meta }) => {
    const option: Option = {
      blockchain: meta.blockchain,
      symbol: meta.symbol,
      icon: meta.icon,
    };
    return option;
  });
}

export function getUsdRate(allocation: Allocation, tokenId: string) {
  return allocation.token_price_usd.find(({ token_id }) => {
    return tokenId === token_id;
  })?.price_usd;
}

export function getPrice(
  allocation: Allocation,
  blockchain: string,
  symbol: string
) {
  return (
    allocation.price_gnet.find(({ meta }) => {
      return meta.symbol === symbol && meta.blockchain === blockchain;
    }) ?? allocation.price_gnet[0]
  );
}
