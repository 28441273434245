import { useEffect, useSyncExternalStore } from "react";

import { AuthData } from "./types";

const initialAuthData = window.localStorage.getItem("auth-data");

let authData: AuthData | undefined = initialAuthData
  ? JSON.parse(initialAuthData)
  : undefined;
const subscribers = new Set<VoidFunction>();

export const authStore = {
  get() {
    return authData;
  },
  set(newAuthData: AuthData | undefined) {
    authData = newAuthData;
    if (newAuthData) {
      localStorage.setItem("auth-data", JSON.stringify(authData));
    } else {
      localStorage.removeItem("auth-data");
    }
    subscribers.forEach((callback) => callback());
  },
  subscribe(callback: VoidFunction) {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  },
};

export function useAuthStore() {
  const authData = useSyncExternalStore(authStore.subscribe, authStore.get);

  return [authData, authStore.set] as const;
}

export function useSyncSession() {
  const [_, setAuth] = useAuthStore();

  useEffect(() => {
    const cb = (event: StorageEvent) => {
      if (event.key === "auth-data") {
        setAuth(event.newValue ? JSON.parse(event.newValue) : undefined);
      }
    };
    window.addEventListener("storage", cb);

    return () => {
      window.removeEventListener("storage", cb);
    };
  }, [setAuth]);
}
