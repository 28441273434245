import type { CHAIN } from "@tonconnect/ui-react";

import { useSDK } from "@metamask/sdk-react";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";

import { Blockchain } from "shared/types";

import { useConnect } from "./use-connect";
import { useDisconnect } from "./use-disconnect";

interface Wallet {
  address: string | undefined;
  isConnected: boolean;
  isDisconnected: boolean;
  isConnecting: boolean;
  chainId: CHAIN | number | undefined;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
}

export function useWallet(
  blockchain: Omit<string, Blockchain> | Blockchain
): Wallet {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const { connected, chainId, account } = useSDK();

  const connect = useConnect(blockchain);
  const disconnect = useDisconnect(blockchain);

  if (blockchain === Blockchain.TON) {
    return {
      address: tonConnectUI.account?.address,
      isConnected: Boolean(wallet),
      isConnecting: tonConnectUI.modalState.status === "opened",
      isDisconnected: !wallet,
      chainId: tonConnectUI.account?.chain,
      connect,
      disconnect,
    };
  }

  const isConnected = Boolean(connected && account);

  return {
    address: account,
    isConnected: isConnected,
    isConnecting: false,
    isDisconnected: !isConnected,
    chainId: +(chainId ?? 0),
    connect,
    disconnect,
  };
}
