import {
  miniApp,
  mockTelegramEnv,
  parseInitData,
  init,
  viewport,
} from "@telegram-apps/sdk-react";

const mockData = {
  auth_date: 1716922846,
  id: 0,
  first_name: "Jonh",
  username: "username",
  last_name: "Doe",
  hash: "89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31",
};

export function mockEnv(tgData = mockData) {
  const initDataRaw = new URLSearchParams([
    [
      "user",
      JSON.stringify({
        id: tgData.id,
        first_name: tgData.first_name,
        last_name: tgData.last_name,
        username: tgData.username,
        language_code: "en",
        is_premium: true,
        allows_write_to_pm: true,
      }),
    ],
    ["hash", tgData.hash],
    ["auth_date", tgData.auth_date.toString()],
    ["start_param", "debug"],
    ["chat_type", "sender"],
    ["chat_instance", "8428209589180549439"],
  ]).toString();

  mockTelegramEnv({
    themeParams: {
      accentTextColor: "#6ab2f2",
      bgColor: "#171717" as `#${string}`,
      buttonColor: "#5288c1",
      buttonTextColor: "#ffffff",
      destructiveTextColor: "#ec3942",
      headerBgColor: "#171717" as `#${string}`,
      hintColor: "#708499",
      linkColor: "#6ab3f3",
      secondaryBgColor: "#232e3c",
      sectionBgColor: "#17212b",
      sectionHeaderTextColor: "#6ab3f3",
      subtitleTextColor: "#708499",
      textColor: "#f5f5f5",
    },
    initData: parseInitData(initDataRaw),
    initDataRaw,
    version: "7.2",
    platform: "tdesktop",
  });
}

export async function initializeTMA() {
  init();
  viewport.expand();
  // const viewport = await viewportPromise;
  // viewport.expand();
  await miniApp.ready();
}
