import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";

import type { Blockchain } from "shared/graphql";

export interface Web3SDKContext {
  blockchain: Blockchain;
  setBlockchain: Dispatch<SetStateAction<Blockchain>>;
}

export const Ctx = createContext<Web3SDKContext | undefined>(undefined);

export function useWeb3SDK() {
  const ctx = useContext(Ctx);
  if (!ctx) throw new Error("useSelectedToken must be used within SdkProvider");

  return ctx;
}
