import { PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

export const Title = (props: PropsWithChildren<Props>) => {
  const { className, children } = props;

  return (
    <h2
      className={twMerge(
        "flex items-center justify-center text-center text-xl font-semibold",
        className
      )}
    >
      {children}
    </h2>
  );
};
