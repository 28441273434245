import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { hapticFeedback } from "@telegram-apps/sdk-react";

import { EnterRefCode, getMessages } from "features/enter-ref-code";
import { PayInBrowserModal } from "features/pay-in-browser";
import { queries } from "shared/api";
import { Allocation, Reservation as ReservationType } from "shared/graphql";
import { useAuth } from "shared/providers/auth";

import { useAttachTxHashFromWeb } from "./hooks/use-attach-tx-hash-from-web";
import { Preloader } from "./preloader";
import { Cooldown } from "./ui/cooldown";
import { Payment } from "./ui/payment";
import { Reservation } from "./ui/reservation";
import { Sale } from "./ui/sale";

export const Page = () => {
  const { user, session } = useAuth();

  const [initialBlockchain, setInitialBlockchain] = useState<
    string | undefined
  >();
  const [initialSymbol, setInitialSymbol] = useState<string | undefined>();
  const [initialNodes, setInitialNodes] = useState<number | undefined>();

  const [isPayInBrowserOpen, setIsPayInBrowserOpen] = useState(false);
  const [payInBrowserTokenId, setPayInBrowserTokenId] = useState<
    string | undefined
  >();
  const [payInBrowserReserveAmount, setpayInBrowserReserveAmount] = useState<
    string | undefined
  >();

  const allocationQuery = useQuery({
    ...queries.allocation({ session }),
    enabled: Boolean(user.ref_code),
  });
  const unpaidReserveQuery = useQuery(queries.unpaidReserve({ session }));
  const paymentQuery = useQuery(
    queries.payment({
      session,
      reservation_proof:
        unpaidReserveQuery.data?.unpaidReserve?.reservation_proof,
    })
  );

  useAttachTxHashFromWeb({
    reservation: unpaidReserveQuery.data?.unpaidReserve ?? undefined,
  });

  if (allocationQuery.isLoading || unpaidReserveQuery.isPending)
    return <Preloader />;

  if (allocationQuery.isError) throw allocationQuery.error;
  if (unpaidReserveQuery.isError) throw unpaidReserveQuery.error;
  if (
    paymentQuery.isError &&
    !paymentQuery.error.message.includes("not found")
  ) {
    throw paymentQuery.error;
  }

  if (!user.ref_code || allocationQuery.data?.status !== "Active") {
    const { title, description } = getMessages(
      Boolean(user.ref_code),
      allocationQuery.data?.status
    );

    return (
      <EnterRefCode
        tierName={allocationQuery.data?.tier_name ?? ""}
        title={title}
        description={description}
      />
    );
  }

  const allocation = allocationQuery.data;
  const reservation = unpaidReserveQuery.data.unpaidReserve;
  const isShard = parseFloat(allocation.fraction) < 1;

  const handleTryInBrowser = (reserveAmount: string, tokenId: string) => {
    hapticFeedback.impactOccurred("medium");
    setpayInBrowserReserveAmount(reserveAmount);
    setPayInBrowserTokenId(tokenId);

    setIsPayInBrowserOpen(true);
  };

  const handleCancelReservation = (reservation: ReservationType) => {
    setInitialBlockchain(reservation.payment_token.meta.blockchain);
    setInitialSymbol(reservation.payment_token.meta.symbol);
    setInitialNodes(reservation.nodes);
  };

  const renderContent = () => {
    if (
      (reservation && reservation.tx_hash) ||
      (reservation && paymentQuery.data?.payment)
    ) {
      return (
        <Payment
          isShard={isShard}
          title={allocation.tier_name}
          payment={paymentQuery.data?.payment}
          reservation={reservation}
          onMount={() => {
            setInitialBlockchain(undefined);
            setInitialNodes(undefined);
            setInitialNodes(undefined);
          }}
        />
      );
    }

    if (reservation && !reservation.tx_hash) {
      return (
        <Reservation
          isShard={isShard}
          allocation={allocation as Allocation}
          reservation={reservation}
          onTryInBrowser={handleTryInBrowser}
          onCancel={handleCancelReservation}
        />
      );
    }

    if (allocation.cooldownReserve) {
      return <Cooldown value={allocation.cooldownReserve} />;
    }

    return (
      <Sale
        isShard={isShard}
        initialBlockchain={initialBlockchain}
        initialSymbol={initialSymbol}
        initialNodes={initialNodes}
        allocation={allocation as Allocation}
        onTryInBrowser={handleTryInBrowser}
      />
    );
  };

  return (
    <>
      {renderContent()}
      {isPayInBrowserOpen &&
        allocation &&
        payInBrowserTokenId &&
        payInBrowserReserveAmount && (
          <PayInBrowserModal
            tokenId={payInBrowserTokenId}
            reserveAmount={payInBrowserReserveAmount}
            reservation={reservation ?? undefined}
            allocation={allocation as Allocation}
            onClose={() => {
              setIsPayInBrowserOpen(false);
              setPayInBrowserTokenId(undefined);
              setpayInBrowserReserveAmount(undefined);
            }}
          />
        )}
    </>
  );
};
