import { twMerge } from "tailwind-merge";

import nodeSvg from "shared/ui/icon/svg/node.svg";
import { Img } from "shared/ui/img";

import fireworksSvg from "../fireworks.svg";

type Props = {
  className?: string;
  name: string;
  img: string;
  fallbackImg?: string;
};

export const Card = (props: Props) => {
  const { name, className, img, fallbackImg } = props;
  return (
    <div
      className={twMerge(
        "relative flex flex-col items-center justify-center gap-y-3 rounded-md border border-white/15 bg-cinder px-6 pb-4 pt-6",
        className
      )}
    >
      <img
        className="absolute -top-7 left-0 size-full"
        src={fireworksSvg}
        alt="fireworks"
      />
      <Img
        className="size-20 shrink-0"
        src={`/images/nodes/${img}`}
        fallbackUrl={fallbackImg ?? nodeSvg}
      />
      <div className="whitespace-nowrap">{name}</div>
    </div>
  );
};
