import { useState } from "react";

import { CurrencyLabel } from "entities/currency";

import { Option } from "../types";
import { Modal } from "./modal";

type Props = {
  disabled?: boolean;
  className?: string;
  symbol: string;
  blockchain: string;
  options: Option[];

  onChange: (option: Option) => void;
};

export function Select(props: Props) {
  const { className, symbol, blockchain, options, disabled, onChange } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectedOption =
    options.find((item) => {
      return item.symbol === symbol && item.blockchain === blockchain;
    }) ?? options[0];

  return (
    <>
      <CurrencyLabel
        className={className}
        symbol={selectedOption.symbol}
        icon={selectedOption.icon}
        onClick={() => {
          if (disabled) return;
          setIsDropdownOpen(!isDropdownOpen);
        }}
      />
      {isDropdownOpen && (
        <Modal
          selectedSymbol={symbol}
          blockchain={blockchain}
          options={options}
          onSelect={(option) => {
            onChange(option);
            setIsDropdownOpen(false);
          }}
          onClose={() => {
            setIsDropdownOpen(false);
          }}
        />
      )}
    </>
  );
}
