import { queryOptions } from "@tanstack/react-query";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import invariant from "tiny-invariant";

import { authStore } from "shared/providers/auth";

import type {
  GetAllocationQueryVariables,
  GetBalanceQuery,
  GetBalanceQueryVariables,
  GetPaymentQueryVariables,
  GetUnpaidReserveQueryVariables,
  User,
} from "../graphql";

import { sdk } from "../providers/graphql-client";

type GetUserOptions = {
  onUserFetch: (user: User, session: string) => Promise<User>;
};

export const queries = {
  all: () => ["queries"] as const,

  allocations: () => [...queries.all(), "allocation"] as const,
  allocation: (variables: Partial<GetAllocationQueryVariables>) => {
    return queryOptions({
      queryKey: [...queries.allocations(), variables],
      queryFn: async () => {
        const { session } = variables;
        invariant(session, "query allocation. session is required");
        const res = await sdk.GetAllocation({ ...variables, session });
        return res.data.allocation;
      },
      enabled: Boolean(variables.session),
    });
  },

  balances: () => [...queries.all(), "balances"] as const,
  balance: (variables: Partial<GetBalanceQueryVariables>) =>
    queryOptions({
      queryKey: [...queries.balances(), variables],
      queryFn: async () => {
        const { session } = variables;
        invariant(session, "query balance. session is required");

        const res = await sdk.GetBalance({ ...variables, session });
        const result: GetBalanceQuery = res.data;
        return result;
      },
      enabled: Boolean(variables.session),
    }),

  payments: () => [...queries.all(), "payments"] as const,
  payment: (variables: Partial<GetPaymentQueryVariables>) =>
    queryOptions({
      queryKey: [...queries.payments(), variables],
      queryFn: async () => {
        const { session, reservation_proof } = variables;
        invariant(session, "query payment. session is required");
        invariant(
          reservation_proof,
          "query payment. reservation_proof is required"
        );
        const response = await sdk.GetPayment({ reservation_proof, session });
        return response.data;
      },
      refetchInterval: (query) => {
        if (
          query.state.error?.message.includes("not found") ||
          (query.state.data &&
            (!query.state.data.payment?.is_accepted ||
              !query.state.data.payment?.is_declined))
        )
          return 3000;
      },
      enabled: Boolean(variables.session && variables.reservation_proof),
    }),

  unpaidReserves: () => [...queries.all(), "unpaid-reserves"] as const,
  unpaidReserve: (variables: Partial<GetUnpaidReserveQueryVariables>) =>
    queryOptions({
      queryKey: [...queries.unpaidReserves(), variables],
      queryFn: async () => {
        const { session } = variables;
        invariant(session, "query unpaidReserve. session is required");

        const res = await sdk.GetUnpaidReserve({ ...variables, session });
        return res.data;
      },
      enabled: Boolean(variables.session),
    }),

  auth: ({ session }: { session?: string }, options: GetUserOptions) => {
    return queryOptions({
      queryKey: [queries.all(), "auth"] as const,
      staleTime: Infinity,
      retry(failureCount, error) {
        if (
          error instanceof Error &&
          (error.message.includes("User not found") ||
            error.message.includes("Invalid session"))
        ) {
          authStore.set(undefined);
        }

        return failureCount < 3;
      },
      queryFn: async () => {
        const getUser = async (session: string) => {
          const getUserResponse = await sdk.GetUser({ session });
          const user = getUserResponse.data.user;
          const modifiedUser = await options?.onUserFetch(user, session);

          return { user: modifiedUser, session };
        };

        if (session) {
          return getUser(session);
        } else {
          const { initDataRaw } = retrieveLaunchParams();
          invariant(initDataRaw, "initDataRaw is undefined");

          const response = await sdk.AuthTelegram({
            telegramInitDataRaw: import.meta.env.DEV
              ? "5bb67cab-10db-4f92-8ba0-0ffcf815dc55"
              : initDataRaw,
          });

          authStore.set(response.data.authTelegram);

          return getUser(response.data.authTelegram.session);
        }
      },
    });
  },
};
