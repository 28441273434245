import { useState } from "react";

import { DateTime, Duration } from "luxon";
import { twJoin } from "tailwind-merge";
import { useEventCallback } from "usehooks-ts";

import { NodesRows } from "entities/node";
import { Border, Layout, LayoutTitle } from "pages/ui/layout";
import { useCancelReserveMutation } from "shared/api";
import { Allocation, Reservation as ReservationType } from "shared/graphql";
import { useCountdown } from "shared/hooks";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import shardSvg from "shared/ui/icon/svg/shard.svg";
import { Link } from "shared/ui/link";
import { formatCurrency, plural } from "shared/utils";
import { SaleFormTitle } from "widgets/sale-form";

type Props = {
  isShard: boolean;
  allocation: Allocation;
  reservation: ReservationType;
  onTryInBrowser: (reserveAmount: string, tokenId: string) => void;
  onCancel: (reservation: ReservationType) => void;
};

export const Reservation = ({
  allocation,
  reservation,
  onTryInBrowser,
  onCancel,
  isShard,
}: Props) => {
  const cancelMutation = useCancelReserveMutation();

  const { amount, meta: currency } = reservation.payment_token;

  const formattedPayAmount = formatCurrency(amount, {
    decimalSeparator: ".",
    disableGroupSeparators: true,
  });

  const [validUntil] = useState(
    DateTime.fromJSDate(new Date(reservation.valid_until))
      .diffNow()
      .rescale()
      .as("seconds")
  );

  const cancelEvent = useEventCallback(() => {
    onCancel(reservation);
    cancelMutation.mutate();
  });

  const [count] = useCountdown(Math.floor(validUntil) ?? 0, cancelEvent);

  const { minutes, seconds } = Duration.fromObject({
    hours: 0,
    minutes: 0,
    seconds: count,
  })
    .normalize()
    .toObject();

  const title = (
    <LayoutTitle className="gap-x-2.5">
      <Icon name="bluewhale" className="size-6" /> BLUWHALE NODE SALE
    </LayoutTitle>
  );

  return (
    <Layout
      contentClasName={twJoin("items-center justify-between pb-6")}
      title={title}
    >
      <Border />
      <SaleFormTitle>{allocation.tier_name}</SaleFormTitle>

      <div className="flex flex-col items-center">
        <div className="flex text-center text-xs font-semibold text-white/50">
          You pay{" "}
          <span className="mx-1 inline-flex text-white">
            {formattedPayAmount} {currency.symbol}
          </span>{" "}
          for
        </div>

        <NodesRows
          className="mt-2"
          count={reservation.nodes}
          img={isShard ? shardSvg : undefined}
          label={
            isShard
              ? plural(count, ["SHARD", "SHARDS", "SHARDS"])
              : plural(count, ["NODE", "NODES", "NODES"])
          }
        />
      </div>

      <div className="flex flex-col justify-center">
        <p className="w-full text-center text-sm uppercase text-white">
          Please, confirm transaction in&nbsp;your wallet
        </p>
        <Button
          isLoading={cancelMutation.isPending}
          onClick={cancelEvent}
          theme="sandstorm-outline"
          style={{
            background:
              "linear-gradient(49.1deg, rgba(238, 210, 146, 0.1) -5.19%, rgba(255, 204, 85, 0.1) 80.03%)",
          }}
          className="relative mt-5 items-center py-3 uppercase text-goldenrod/50 inner-border-transparent"
        >
          <span className="mr-2 font-minecraft text-xs text-goldenrod">
            {minutes?.toString().padStart(2, "0")}:
            {seconds?.toString().padStart(2, "0")}
          </span>
          <span>left to buy</span>
          <Icon
            name="cross"
            className="absolute right-3.5 top-1/2 size-4 -translate-y-1/2 text-goldenrod"
          />
        </Button>

        <p className="mt-5 flex items-center justify-center gap-x-1 text-xs">
          Doesn't work? <Icon name="metamask" className="size-4" />{" "}
          <Link
            as="button"
            className="text-goldenrod underline"
            onClick={() =>
              onTryInBrowser(
                reservation.gnet_amount,
                reservation.payment_token.meta.token_id
              )
            }
          >
            Try on Web app
          </Link>
        </p>
      </div>
    </Layout>
  );
};
