import { useCallback } from "react";

import { useSDK } from "@metamask/sdk-react";
import { hapticFeedback } from "@telegram-apps/sdk-react";
import { useTonConnectUI } from "@tonconnect/ui-react";

import { Blockchain } from "shared/graphql";

export function useConnect(blockchain: Omit<string, Blockchain> | Blockchain) {
  const { sdk } = useSDK();

  const [tonConnectUI] = useTonConnectUI();

  return useCallback(async () => {
    hapticFeedback.impactOccurred("medium");
    try {
      if (blockchain === "TON") {
        return await tonConnectUI.openModal();
      }

      await sdk?.connect();
      return;
    } catch (error) {
      console.error(error);
    }
  }, [blockchain, sdk, tonConnectUI]);
}
