import { useMutation } from "@tanstack/react-query";

import type { AttachTxHashToReservationMutationVariables } from "shared/graphql";

import { useAuth } from "shared/providers/auth";
import { sdk } from "shared/providers/graphql-client";

export function useAttachHashToReservationMutation() {
  const { session } = useAuth();
  return useMutation({
    mutationFn: async (
      variables: Omit<AttachTxHashToReservationMutationVariables, "session">
    ) => {
      const response = await sdk.AttachTxHashToReservation({
        ...variables,
        session,
      });
      return response;
    },
  });
}
