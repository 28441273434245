import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AuthData } from "shared/providers/auth/types";

import type { AttachRefCodeMutationVariables } from "../graphql";

import { sdk } from "../providers/graphql-client";
import { queries } from "./query-factory";

export function useAttachRefCodeMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: AttachRefCodeMutationVariables) => {
      const res = await sdk.AttachRefCode(variables);

      return res.data;
    },

    onSuccess: ({ attachRefCode }) => {
      queryClient.setQueryData(
        [queries.all(), "auth"] as const,
        (data: AuthData | undefined) => {
          if (!data) return;
          return {
            ...data,
            user: {
              ...data.user,
              ref_code: attachRefCode.ref_code,
            },
          };
        }
      );
    },
  });
}
