import { twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";
import { Spinner } from "shared/ui/spinner";

export type HistoryStatus = "pending" | "error" | "received";

type Props = {
  className?: string;
  blockchain: string;
  status: HistoryStatus;
};

const statusMap = {
  pending: {
    icon: <Spinner className="size-3.5 text-osloGrey" />,
    title: "Pending...",
  },
  received: {
    icon: <Icon name="rectArrowDown" className="size-3 text-osloGrey" />,
    title: "Received",
  },
  error: {
    icon: <Icon name="cross" className="size-2.5 text-osloGrey" />,
    title: "Failed",
  },
} as const satisfies Record<HistoryStatus, object>;

export const Status = (props: Props) => {
  const { status, blockchain, className } = props;
  const { icon, title } = statusMap[status];
  return (
    <div
      className={twMerge("flex items-center gap-x-1.5 text-white", className)}
    >
      <div className="flex size-5 items-center justify-center rounded-full bg-darkGrey/75">
        {icon}
      </div>
      <span className="inline-flex">{title}</span>
      <div className="rounded-full border border-white/10 px-1.5 py-0.5 text-[10px] font-medium text-white/50">
        {blockchain}
      </div>
    </div>
  );
};
