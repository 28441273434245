import { AllocationStatus } from "shared/graphql";

export function getMessages(
  hasRefCode: boolean,
  allocationStatus: AllocationStatus | undefined
): { title: string; description: string } {
  if (!hasRefCode) {
    return {
      title: "Your link is invalid.",
      description: "Please, try using another one or enter referral code.",
    };
  }

  if (allocationStatus === "SoldOut" || allocationStatus === "Closed") {
    return {
      title: "Oops, this node pool is sold out...",
      description: "Try to get a link for another pool or enter referral code.",
    };
  }

  if (allocationStatus === "FullyReserved") {
    return {
      title: "Oops, this node pool is fully reserved...",
      description:
        "You can wait here and try your luck, or check other available pools.",
    };
  }

  return { title: "", description: "" };
}
