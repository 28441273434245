import Decimal from "decimal.js";
import { Address } from "viem";
import { useAccount } from "wagmi";

import { ConnectWalletWebButton } from "features/connect-wallet";
import { Option } from "features/select-currency";
import { LayoutTitle } from "pages/ui/layout";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { noop, plural } from "shared/utils";
import { chainMap } from "shared/web3/consts";
import {
  SaleFormInputData,
  SaleFormOutputData,
  SaleFormSeparator,
  SaleFormTitle,
} from "widgets/sale-form";
import { BottomInfo } from "widgets/sale-form/ui/bottom-info";

import logoSvg from "./Logo.svg";
import { useBuyMutation } from "./use-buy-mutation";
import { useData } from "./use-data";

export const Web = () => {
  const {
    fraction,
    availableNodes,
    totalNodes,
    tierName,
    usdRate,
    blockchain,
    symbol,
    payValue,
    icon,
    nodes,
    reservationProof,
    paymentAddress,
    decimal,
    contractAddress,
  } = useData();

  const { isConnected, chainId } = useAccount();

  const options: Option[] = [{ blockchain, symbol, icon }];
  const targetChainId = chainMap[blockchain];
  const shouldSwitch = targetChainId !== chainId;

  const buyMutation = useBuyMutation();

  if (blockchain === "TON") throw new Error("TON is not supported here");

  const handleBuy = async () => {
    buyMutation.mutate(
      {
        reservationProof,
        paymentAddress,
        contractAddress: contractAddress as Address,
        currencyAmount: payValue,
        currencyDecimal: decimal,
      },
      {
        onSuccess: (txHash) => {
          console.log(txHash);
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  const isShard = parseFloat(fraction) < 1;
  const label = isShard
    ? plural(nodes, ["SHARD", "SHARDS", "SHARDS"])
    : plural(nodes, ["NODE", "NODES", "NODES"]);

  return (
    <div className="flex h-dvh flex-col overflow-y-auto p-2.5">
      <div className="m-auto flex flex-col">
        <img className="mx-auto h-9 w-40" src={logoSvg} alt="" />
        <div className="mx-auto my-[25px] flex h-[96px] w-[352px] items-center justify-center space-x-[15px] rounded-[10px] border border-[#FF5751]/50">
          <Icon name="alertCircle" className="size-[52px]" />
          <div className="w-[238px] text-[12px] leading-[120%] text-[#FF5751]">
            DO NOT use this link if it was sent to you by someone you don't
            know. Only use it if it was copied from{" "}
            <a
              className="underline"
              href="https://t.me/BluwhaleNodesaleBot/app"
              target="_blank"
            >
              CT.COM Nodesale
            </a>{" "}
            app opened with your telegram account.
          </div>
        </div>
        <div className="flex flex-col rounded-2xl border border-white/5 pt-11">
          <div className="flex flex-col px-9">
            <LayoutTitle className="gap-x-2.5">
              <Icon name="bluewhale" className="size-6" /> BLUWHALE NODE SALE
            </LayoutTitle>
            <SaleFormTitle className="mt-2.5">{tierName}</SaleFormTitle>
            <SaleFormInputData
              disabled
              className="mt-8"
              options={options}
              payValue={payValue ?? undefined}
              usdRate={usdRate ?? "0"}
              symbol={symbol}
              blockchain={blockchain}
              decimals={6}
              onPayValueChange={noop}
              onCurrencySelect={noop}
            />
            {isShard && (
              <span className="mt-2 flex justify-center text-sm text-goldenrod/50">
                1 NODE = {new Decimal(1).div(fraction || 1).toString()} SHARDS
              </span>
            )}

            <SaleFormSeparator />
            <SaleFormOutputData
              className="mb-10"
              disabled
              max={nodes}
              min={nodes}
              onChange={noop}
              value={nodes}
              label={label}
            />

            {buyMutation.data && (
              <Button
                as="a"
                href={
                  window.origin.includes("ct-node-sale")
                    ? `https://t.me/BluwhaleNodesaleBot/app?startapp=${buyMutation.data}`
                    : `https://t.me/BluwhaleNodesale_bot/app?startapp=${buyMutation.data}`
                }
              >
                <Button.Content>Back to TG</Button.Content>
              </Button>
            )}

            {isConnected && !shouldSwitch && !buyMutation.data && (
              <Button
                disabled={!nodes}
                isLoading={buyMutation.isPending}
                onClick={handleBuy}
              >
                <Button.Content>Buy for {symbol}</Button.Content>
              </Button>
            )}

            {!buyMutation.data && (
              <ConnectWalletWebButton blockchain={blockchain} />
            )}
          </div>

          <BottomInfo
            availableNodes={availableNodes}
            totalNodes={totalNodes}
            label={isShard ? "SHARDS" : "NODES"}
            className="mt-10 border-white/5"
          />
        </div>
      </div>
    </div>
  );
};
