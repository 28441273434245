import { twMerge } from "tailwind-merge";

import { Icon } from "shared/ui/icon";

type Props = {
  className?: string;
};

export const TopDescription = ({ className }: Props) => {
  return (
    <div
      className={twMerge(
        "text-center font-inter text-sm font-light text-smokeyGrey",
        className
      )}
    >
      <span>Supported networks</span>
      <div className="mt-[8px] flex flex-wrap items-center justify-center space-x-[10px]">
        <Icon name="ton" />
        <Icon name="eth" />
        <Icon name="bsc" />
        {/* <Icon name="arb" /> */}
        {/* <Icon name="pol" /> */}
        <Icon name="base" />
        <Icon name="op" />
      </div>
      {/* <span className="flex items-center gap-x-1 text-bone">
        TON <Icon name="ton" />
      </span>
      <span className="flex items-center gap-x-1 text-bone">
        ETH <Icon name="eth" />
      </span>
      <span className="flex items-center gap-x-1 text-bone">
        BSC <Icon name="bsc" />
      </span>
      <span className="flex items-center gap-x-1 text-bone">
        ARB <Icon name="arb" />
      </span>
      <span className="flex items-center gap-x-1 text-bone">
        POL <Icon name="pol" />
      </span>
      <span className="flex items-center gap-x-1 text-bone">
        BASE <Icon name="base" />
      </span>
      <span className="flex items-center gap-x-1 text-bone">
        OP <Icon name="op" />
      </span> */}
    </div>
  );
};
