import { Stringified } from "type-fest";

import { Data } from "pages/web/use-data";
import { Allocation, Reservation } from "shared/graphql";

import { getUsdRate } from "./sale";

export function generateWebLink(
  allocation: Allocation,
  reservation: Reservation
): string {
  const params = new URLSearchParams({
    fraction: allocation.fraction,
    reservationProof: reservation.reservation_proof,
    paymentAddress: reservation.payment_token.meta.payment_address ?? "",
    payValue: reservation.payment_token.amount,
    decimal: reservation.payment_token.meta.decimals.toString(),
    nodes: reservation.nodes.toString(),
    blockchain: reservation.payment_token.meta.blockchain,
    symbol: reservation.payment_token.meta.symbol,
    availableNodes: allocation.available_nodes.toString(),
    contractAddress: reservation.payment_token.meta.contract_address ?? "",
    icon: reservation.payment_token.meta.icon,
    totalNodes: allocation.total_nodes.toString(),
    tierName: allocation.tier_name,
    usdRate:
      getUsdRate(allocation, reservation.payment_token.meta.token_id) ?? "",
  } as Stringified<Data>);

  const url = new URL(
    window.origin.includes("bluwhale-nodesale")
      ? "https://bluwhale-nodesale-tma-dev.pages.dev/web"
      : "https://app.ct-node-sale.com/web"
  );
  url.search = params.toString();

  return url.toString();
}
