import { ClientError, GraphQLClient } from "graphql-request";

import { getSdk } from "shared/graphql";

import { authStore } from "../auth";

const url = import.meta.env.VITE_GRAPHQL_ENDPOINT;

export const client = new GraphQLClient(
  url.startsWith("/") ? `${window.origin}${url}/graphql` : `${url}/graphql`,
  {
    credentials: "include",
    responseMiddleware: (response) => {
      if (response instanceof ClientError && response.response.status === 401) {
        authStore.set(undefined);
      }
    },
  }
);

export const sdk = getSdk(client, (action) => {
  const data = authStore.get();

  return action({
    Authorization: `Bearer ${data?.session}`,
  });
});
