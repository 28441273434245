import { PropsWithChildren, useState } from "react";
import { Outlet } from "react-router-dom";

import { useLocalStorage } from "usehooks-ts";

import { Border } from "pages/ui/layout";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";

import { PolicyCheckbox } from "./policy-checkbox";
import { TopDescription } from "./top-description";

export const Welcome = ({ children }: PropsWithChildren) => {
  const [isAgreed, setIsAgreed] = useLocalStorage(
    "is-agreed-with-terms",
    false
  );

  const [isChecked, setIsChecked] = useState(isAgreed);

  const handleClick = () => {
    setIsAgreed(isChecked);
  };

  if (isAgreed) return children ?? <Outlet />;

  return (
    <div className="flex grow flex-col px-5 pb-6 pt-3.5">
      <div className="relative  flex grow flex-col items-center justify-between bg-[length:100%] bg-center bg-no-repeat pb-6">
        <Border type="big" />
        <TopDescription className="mt-5" />

        <div className="flex flex-col items-center gap-y-10">
          <Icon name="ctxbluewhale" className="flex h-[73px] w-[250px]" />
          <p className="text-center">
            <span className="text-[28px] text-goldenrod">
              Bluwhale Nodesale
            </span>
            <br />
            <span className="font-redhat text-[18px] text-bone">on CT.com</span>
          </p>
        </div>

        <div className="flex flex-col items-center px-8">
          <PolicyCheckbox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />

          <Button
            disabled={!isChecked}
            className="mt-5 w-full uppercase"
            onClick={handleClick}
          >
            <Button.Content>Let's go</Button.Content>
          </Button>

          {/* <div className="mt-5 flex gap-x-3 text-center text-xs text-goldenrod">
            <div className="rounded-md bg-goldenrod/15 px-2 py-1.5">
              More about CT.COM
            </div>
            <div className="rounded-md bg-goldenrod/15 px-2 py-1.5">
              More about Nodesale
            </div>
            <div className="rounded-md bg-goldenrod/15 px-2 py-1.5">
              Vesting and TGE terms
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
