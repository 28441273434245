import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLaunchParams } from "@telegram-apps/sdk-react";
import { Hash, isHex, zeroHash } from "viem";

import { queries, useAttachHashToReservationMutation } from "shared/api";
import { Reservation } from "shared/graphql";

type Props = {
  reservation: Reservation | undefined;
};

let bindTx: Hash = zeroHash;

export const useAttachTxHashFromWeb = ({ reservation }: Props) => {
  const queryClient = useQueryClient();
  const launchParams = useLaunchParams();
  const attachMutation = useAttachHashToReservationMutation();

  const txHash = isHex(launchParams.startParam)
    ? launchParams.startParam
    : zeroHash;

  useEffect(() => {
    if (!reservation || bindTx === txHash) return;

    bindTx = txHash;
    attachMutation.mutate(
      {
        reservation_proof: reservation?.reservation_proof,
        tx_hash: bindTx,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: queries.unpaidReserves(),
          });
        },
      }
    );
  }, [txHash, attachMutation, reservation, queryClient]);
};
