import { useMutation, useQueryClient } from "@tanstack/react-query";
import { hapticFeedback } from "@telegram-apps/sdk-react";

import type { ReserveInput } from "shared/graphql";

import { queries } from "shared/api";
import { useAuth } from "shared/providers/auth";
import { sdk } from "shared/providers/graphql-client";
import { useSendTransaction } from "shared/providers/web3-sdk";
import { sleep } from "shared/utils";

export function useBuyMutation() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  const sendTransaction = useSendTransaction();

  return useMutation({
    mutationFn: async (variables: ReserveInput) => {
      await sleep(1000);
      const newReservation = await sdk.Reserve({
        session,
        reserve: variables,
      });

      // does it needed?
      await queryClient.invalidateQueries({
        queryKey: queries.unpaidReserves(),
      });

      if (newReservation.errors) {
        // hapticFeedback.notificationOccurred('error')
        // queryClient.invalidateQueries({ queryKey: queries.allocations() })
        throw new Error("reservation error");
      }

      const hash = await sendTransaction(newReservation.data.reserve);

      if (!hash) {
        throw new Error("sendTransaction error");
      }

      await sdk.AttachTxHashToReservation({
        session,
        tx_hash: hash,
        reservation_proof: newReservation.data.reserve.reservation_proof,
      });
      return { newReservation, hash };
    },
    onError: (error) => {
      hapticFeedback.notificationOccurred("error");
      console.error("error: ", error);
    },
    onSettled: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({ queryKey: queries.unpaidReserves() }),
        // queryClient.invalidateQueries({ queryKey: queries.allocations() }),
      ]);
    },
  });
}
