import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { isAddress, zeroAddress } from "viem";
import { z } from "zod";

const zodSchema = z.object({
  fraction: z.string(),
  availableNodes: z.coerce.number(),
  totalNodes: z.coerce.number(),
  tierName: z.string(),
  usdRate: z.string().nullable(),
  blockchain: z.string(),
  symbol: z.string(),
  payValue: z.string(),
  icon: z.string(),
  nodes: z.coerce.number(),

  reservationProof: z.string(),
  paymentAddress: z.string().refine((value) => isAddress(value)),
  contractAddress: z.string().refine((value) => isAddress(value)),
  decimal: z.coerce.number(),
});

export type Data = z.infer<typeof zodSchema>;

export const useData = () => {
  const [searchParams] = useSearchParams();
  const stringifiedData = useMemo(() => {
    const stringifiedData = {
      fraction: searchParams.get("fraction"),
      availableNodes: searchParams.get("availableNodes"),
      totalNodes: searchParams.get("totalNodes"),
      tierName: searchParams.get("tierName"),
      usdRate: searchParams.get("usdRate"),
      blockchain: searchParams.get("blockchain"),
      symbol: searchParams.get("symbol"),
      payValue: searchParams.get("payValue"),
      icon: searchParams.get("icon"),
      nodes: searchParams.get("nodes") ?? "0",
      reservationProof: searchParams.get("reservationProof"),
      paymentAddress: searchParams.get("paymentAddress"),
      contractAddress: searchParams.get("contractAddress") || zeroAddress,
      decimal: searchParams.get("decimal"),
    };

    const data = zodSchema.parse(stringifiedData);

    return data;
  }, [searchParams]);

  return stringifiedData;
};
