import { CHAIN } from "@tonconnect/ui-react";
import {
  arbitrum,
  base,
  bsc,
  optimism,
  polygon,
  sepolia,
  mainnet,
} from "viem/chains";

export const chainMap: Record<string, number | string> = {
  TON: CHAIN.MAINNET,
  Ethereum: import.meta.env.VITE_ENV === "prod" ? mainnet.id : sepolia.id,
  BSC: bsc.id,
  Arbitrum: arbitrum.id,
  BASE: base.id,
  Polygon: polygon.id,
  Optimism: optimism.id,
};
