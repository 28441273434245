import { TxHistoryStatus } from "entities/tx";
import { UserPayment } from "shared/graphql";

export const getPaymentHistoryStatus = (
  payment: UserPayment
): TxHistoryStatus => {
  if (payment.is_declined) return "error";
  if (payment.is_accepted) return "received";
  return "pending";
};
