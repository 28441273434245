export function getExplorerLink(txHash: string, blockchain: string) {
  switch (blockchain) {
    case "TON":
      return `https://tonscan.org/tx/${txHash}`;
    case "Ethereum":
      return `https://etherscan.io/tx/${txHash}`;
    case "Arbitrum":
      return `https://arbiscan.io/tx/${txHash}`;
    case "BASE":
      return `https://basescan.org/tx/${txHash}`;
    case "BSC":
      return `https://bscscan.com/tx/${txHash}`;
    case "Optimism":
      return `https://optimistic.etherscan.io/tx/${txHash}`;
    case "Polygon":
      return `https://polygonscan.com/tx/${txHash}`;
    default:
      return "/";
  }
}
