import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  availableNodes: number;
  totalNodes: number;
  label: string;
};

export const BottomInfo = (props: Props) => {
  const { availableNodes, label, className } = props;
  return (
    <div
      className={twMerge(
        "mx-auto flex w-full items-center justify-between text-xs font-semibold",
        className
      )}
    >
      <span className="inline-flex w-full border-t border-white/5 p-6 py-2.5 text-white/50">
        {availableNodes} {label} Available
      </span>
      <a
        className="inline-flex w-28 justify-center border-l border-t border-white/5 py-2.5 pr-4"
        href="https://ct-com.gitbook.io/ct.com-faq"
        target="_blank"
      >
        FAQ
      </a>
    </div>
  );
};
