import { useEffect } from "react";

import {
  useCountdown as useCountdownHook,
  useEventCallback,
} from "usehooks-ts";

type Options = {
  startImmediately: boolean;
  intervalMs: number;
};

const defaultOptions: Options = {
  startImmediately: true,
  intervalMs: 1000,
};

export const useCountdown = (
  countStart: number,
  finishCb: () => void,
  options = defaultOptions
) => {
  const finishEvent = useEventCallback(finishCb);

  const [count, actions] = useCountdownHook({
    countStart,
    ...options,
  });

  const startCountdown = actions.startCountdown;

  useEffect(() => {
    if (options.startImmediately) startCountdown();
  }, [options.startImmediately, startCountdown]);

  useEffect(() => {
    if (count === 0) finishEvent();
  }, [count, finishEvent]);

  return [count, actions] as const;
};
