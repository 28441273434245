import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  date: string;
};
export const Separator = (props: Props) => {
  const { date, className } = props;
  return (
    <div
      className={twMerge(
        "flex items-center justify-between gap-x-3.5",
        className
      )}
    >
      <div className="h-px w-full bg-white/20" />
      <div className="shrink-0 grow text-xs text-white/50">{date}</div>
      <div className="h-px w-full bg-white/20" />
    </div>
  );
};
