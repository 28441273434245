import Skeleton from "react-loading-skeleton";

import { Border, Layout } from "pages/ui/layout";

import { PreloaderRow } from "./preloader-row";

export const Preloader = () => {
  return (
    <Layout>
      <Border />
      <Skeleton className="h-3.5 w-1/2 rounded" />
      <Skeleton className="mt-3.5 h-11 w-full rounded" />

      <div className="mt-10 flex items-center gap-x-2.5">
        <div className="h-px w-full bg-white/20" />
        <Skeleton className="h-3 w-[53px] rounded" />
        <div className="h-px w-full bg-white/20" />
      </div>
      <PreloaderRow className="mt-7.5" />
      <PreloaderRow className="mt-7.5" />

      <div className="mt-10 flex items-center gap-x-2.5">
        <div className="h-px w-full bg-white/20" />
        <Skeleton className="h-3 w-[53px] rounded" />
        <div className="h-px w-full bg-white/20" />
      </div>

      <PreloaderRow className="mt-7.5" />
      <PreloaderRow className="mt-7.5" />
    </Layout>
  );
};
