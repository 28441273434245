import { useCallback } from "react";

import { useSDK } from "@metamask/sdk-react";
import { beginCell, toNano } from "@ton/core";
import { Address } from "@ton/ton";
import {
  type SendTransactionRequest,
  type TonConnectUI,
  TonConnectUIError,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import {
  type Address as EVMAddress,
  concat,
  decodeFunctionData,
  encodeFunctionData,
  erc20Abi,
  parseEther,
  parseUnits,
  toHex,
} from "viem";

import type { Reservation } from "shared/graphql";

import { Blockchain } from "shared/types";
import { chainMap } from "shared/web3/consts";

import { deriveJettonWalletAddress } from "../utils/deriveJettonWalletAddress";
import { getTxHashFromBocString } from "../utils/getTxHashFromBocString";
import { prepareJettonTransferMessage } from "../utils/prepareJettonTransferMessage";

export function useSendTransaction() {
  const { sdk, account, provider } = useSDK();
  const [tonConnectUI] = useTonConnectUI();

  return useCallback(
    async (reserve: Reservation) => {
      const selectedToken = reserve.payment_token.meta;
      if (!selectedToken) {
        throw new Error("selectedToken is undefined");
      }
      if (
        selectedToken.blockchain === Blockchain.TON &&
        selectedToken.type === "Native"
      ) {
        return await payFromTelegramWallet(reserve, tonConnectUI);
      }

      if (
        selectedToken.blockchain === Blockchain.TON &&
        selectedToken.type === "Jetton"
      ) {
        if (
          !reserve.payment_token.meta.payment_address ||
          !tonConnectUI.account?.address ||
          !reserve.payment_token.meta.contract_address
        )
          return;
        const messageBody = prepareJettonTransferMessage(
          parseUnits(
            reserve.payment_token.amount.toString(),
            reserve.payment_token.meta.decimals
          ),
          Address.parse(reserve.payment_token.meta.payment_address),
          Address.parse(tonConnectUI.account.address),
          toNano("0.01"),
          reserve.reservation_proof
        );

        const jettonWalletAddress = deriveJettonWalletAddress(
          Address.parse(reserve.payment_token.meta.contract_address),
          Address.parse(tonConnectUI.account.address)
        );

        const hash = await tonConnectUI.sendTransaction({
          validUntil: Math.floor(
            new Date(reserve.valid_until).getTime() / 1000
          ),
          messages: [
            {
              address: jettonWalletAddress.toString(),
              amount: toNano(0.08).toString(),
              payload: messageBody.toBoc().toString("base64"),
            },
          ],
        });

        return getTxHashFromBocString(hash.boc);
      }

      // if (!address || !wc) {
      //   throw new Error('\'address | wc are undefined\'')
      // }

      if (!account || !sdk || !provider) {
        throw new Error("'account | sdk | provider are undefined'");
      }

      console.log(reserve.payment_token);

      const hexedProof = toHex(reserve.reservation_proof);
      let data = hexedProof;
      if (selectedToken.type === "ERC20") {
        const transferData = encodeFunctionData({
          abi: erc20Abi,
          functionName: "transfer",
          args: [
            selectedToken.payment_address as EVMAddress,
            parseUnits(
              reserve.payment_token.amount.toString(),
              reserve.payment_token.meta.decimals
            ),
          ],
        });

        console.error(
          "transfer_data",
          decodeFunctionData({ abi: erc20Abi, data: transferData })
        );
        data = concat([transferData, hexedProof]);
      }

      const transactionParameters = {
        from: account,
        chainId: toHex(chainMap[selectedToken.blockchain]),
        to:
          selectedToken.type === "ERC20"
            ? (selectedToken.contract_address as EVMAddress)
            : (selectedToken.payment_address as EVMAddress),
        value:
          selectedToken.type === "ERC20"
            ? undefined
            : toHex(parseEther(reserve.payment_token.amount.toString(), "wei")),
        data: data as `0x${string}`,
        gas: toHex(200000),
      };

      // const chain = supportedChains.find(
      //   (ch) => ch.id === chainMap[selectedToken.blockchain]
      // );

      // const publicClient = createPublicClient({
      //   chain,
      //   transport: http(chain?.rpcUrls.default.http[0]),
      // });

      console.error("NOT ERROR transactionParameters", transactionParameters);

      // const gasResult = await publicClient.estimateGas({
      //   data: data,

      //   account: account as EVMAddress,
      //   to: transactionParameters.to as EVMAddress,
      //   value: transactionParameters.value
      //     ? fromHex(transactionParameters.value, "bigint")
      //     : 0n,
      // });
      // transactionParameters.gas = toHex(gasResult);

      const hash = (await provider?.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      })) as string;

      return hash;
    },
    [tonConnectUI, sdk, account, provider]
  );
}

export async function payFromTelegramWallet(
  reserve: Reservation,
  tonConnectUI: TonConnectUI
) {
  let transaction: SendTransactionRequest | undefined;
  if (reserve.payment_token.meta.type === "Native") {
    transaction = await prepareTONNativeTX(reserve);
  } else {
    throw new Error("Unsupported token type");
  }

  try {
    const sendTxResp = await tonConnectUI.sendTransaction(transaction);
    const txhash = getTxHashFromBocString(sendTxResp.boc);

    return txhash;
  } catch (error) {
    // check if error is TonConnectUIError
    if (error instanceof TonConnectUIError) {
      console.error("TonConnectUIError", error);
      // TODO: handle error
    }
  }
}

async function prepareTONNativeTX(
  reserve: Reservation
): Promise<SendTransactionRequest> {
  const body = beginCell()
    .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
    .storeStringTail(reserve.reservation_proof) // write our text comment
    .endCell();

  const tx = {
    validUntil: Math.floor(new Date(reserve.valid_until).getTime() / 1000),
    messages: [
      {
        address: reserve.payment_token.meta.payment_address || "",
        amount: toNano(reserve.payment_token.amount).toString(),
        payload: body.toBoc().toString("base64"), // payload with comment in body
      },
    ],
  };

  return tx;
}
