import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { MetaMaskProvider } from "@metamask/sdk-react";
import { openLink } from "@telegram-apps/sdk-react";

export const MetaMaskSDKProvider = ({ children }: PropsWithChildren) => {
  return (
    <MetaMaskProvider
      debug={import.meta.env.DEV}
      sdkOptions={{
        logging: {
          developerMode: false,
        },
        useDeeplink: false,
        communicationServerUrl: undefined,
        checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
        i18nOptions: {
          enabled: true,
        },
        dappMetadata: {
          name: "CT.com app",
          url: `${window.location.protocol}//${window.location.host}`,
        },
        openDeeplink: (url: string) => {
          if (url.match(/market:\/\/details/)) {
            return openLink(
              "https://play.google.com/store/apps/details?id=io.metamask"
            );
          }
          openLink(url);
        },
      }}
    >
      {children ?? <Outlet />}
    </MetaMaskProvider>
  );
};
